.content-w-aside__content {
  @include media-breakpoint-up(xl) {
    grid-column: 1 / span 8;
  }

  .container {
    padding-right: 0;
    padding-left: 0;
  }

  .s-content .entry-content {
    h2,
    h3,
    h4,
    > p,
    > ul,
    > ol,
    > blockquote {
      padding-inline: 24px;

      @include media-breakpoint-down(sm) {
        padding-inline: 16px;
      }
    }
  }
}

.content-w-aside__row {
  gap: 32px;

  @include media-breakpoint-up(xl) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }

  & > .content-w-aside__content:last-child {
    grid-column: 3 / span 8;
  }
}

.content-w-aside__aside {
  container-type: inline-size;

  > div {
    margin-top: -1px;
  }

  @include media-breakpoint-up(xl) {
    grid-column: span 4/ -1;
  }
}

.s-map__circle-decoration {
  right: 0;

  @include media-breakpoint-down(xxxl) {
    width: 88px;
  }

  @include media-breakpoint-down(xxl) {
    width: 72px;
  }

  @include media-breakpoint-down(xl) {
    width: 60px;
  }

  @include media-breakpoint-down(lg) {
    width: 44px;
  }

  @include media-breakpoint-down(md) {
    width: 80px;
  }

  @include media-breakpoint-down(sm) {
    width: 60px;
  }
}

.i-post {
  --radius: 56px;
  --padding: 24px;
  --gap: 40px;

  gap: var(--gap);
  border-radius: var(--radius);
  padding: var(--padding);

  @include media-breakpoint-down(xxxl) {
    --padding: 16px;
    --radius: 48px;
    --gap: 32px;
  }

  @include media-breakpoint-down(sm) {
    --radius: 40px;
    --gap: 24px;
  }
}

.i-post__image-link {
  --radius: 40px;

  border-radius: var(--radius);

  @include media-breakpoint-down(sm) {
    --radius: 32px;
  }
}

.i-post__heading-link {
  text-wrap: balance;

  &:hover {
    color: var(--c-text);
  }
}

.i-post__content-col {
  --padding: 16px;

  padding: 0 var(--padding) var(--padding);
  gap: var(--gap);

  @include media-breakpoint-down(sm) {
    --padding: 8px;
  }
}

.i-post__footer {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
  }
}

.i-post__tags {
  @include media-breakpoint-down(sm) {
    display: none;

    &.--open {
      display: block;
    }
  }
}

.i-post__tags-expand {
  @include media-breakpoint-down(sm) {
    &.--hide {
      display: none;
    }
  }
}

.i-post__tags-close {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

@use "settings/s-colors" as colors;

.f-error {
  --display: none;
  --left: 32px;

  position: absolute;
  top: calc(100% + 8px);
  left: var(--left);
  color: var(--c-danger);
  width: 100%;
  display: var(--display);
  z-index: 2;

  &.--show {
    --display: flex;
  }

  @include media-breakpoint-down(xxxl) {
    --left: 24px;
  }

  @include media-breakpoint-down(sm) {
    --left: 8px;
  }
}

.f-error__icon {
  height: 22px;
}

.f-error__title {
  line-height: 22px;
}

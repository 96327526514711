.s-text-media__grid {
  column-gap: 112px;
  row-gap: 32px;

  @include media-breakpoint-down(xxxl) {
    column-gap: 64px;
  }

  @include media-breakpoint-down(xxl) {
    column-gap: 32px;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-text-media__header-in {
  gap: 8px;
  margin-bottom: 56px;

  @include media-breakpoint-down(xl) {
    margin-bottom: 24px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}

.s-text-media__col {
  &.--content {
    padding-left: 0;
    padding-right: 24px;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      padding-inline: 16px;
    }
  }

  &.--media {
    @include media-breakpoint-down(xl) {
      margin-inline: auto;
      max-width: 896px;
    }
  }
}

.s-text-media {
  --radius: 56px;

  .img {
    border-radius: var(--radius);
  }

  .g-buttons {
    --spacing: 56px;
  }

  &.--reverse {
    .s-text-media__col.--content {
      order: -1;
      padding-left: 24px;
      padding-right: 0;

      @include media-breakpoint-down(xl) {
        padding-left: 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: 16px;
      }

      @include media-breakpoint-down(lg) {
        order: 1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    --radius: 40px;
  }
}

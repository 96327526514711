.main-footer__columns {
  --p-block: 80px;

  padding-block: var(--p-block);
  border-top: 1px solid var(--c-secondary);
  border-bottom: 1px solid var(--c-secondary);
  row-gap: 72px;
  column-gap: 32px;

  @include media-breakpoint-down(xxxl) {
    --padding: 72px;
  }

  @include media-breakpoint-down(md) {
    --padding: 48px;
  }

  @include media-breakpoint-between(md, xxl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.main-footer {
  --margin-top: 84px;
  --p-inline: 64px;

  @include media-breakpoint-down(xxxl) {
    --p-inline: 56px;
  }

  @include media-breakpoint-down(md) {
    --p-inline: 32px;
  }

  margin-top: var(--margin-top);

  @include media-breakpoint-down(xxxl) {
    --margin-top: 72px;
  }

  @include media-breakpoint-down(md) {
    --margin-top: 40px;
  }
}

.main-footer__socials {
  @include media-breakpoint-down(md) {
    gap: 24px;
  }
}

.main-footer__bottom {
  --p-block: 48px;

  padding-block: var(--p-block);
}

.main-footer__bottom-inner,
.main-footer__columns {
  padding-inline: var(--p-inline);
}

@use "settings/s-colors" as colors;

.g-colors {
  width: fit-content;
  border-radius: 56px;
  padding: 32px;
  padding-right: 246px;
  border: 1px solid var(--c-secondary);

  @include media-breakpoint-down(xl) {
    padding: 16px;
    padding-right: 123px;
  }
}

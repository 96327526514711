.img-diamant {
  --size: 80px;
  --offset: 24px;

  width: var(--size);

  @include media-breakpoint-down(xxxl) {
    --size: 72px;
  }

  @include media-breakpoint-down(xl) {
    --size: 60px;
  }

  @include media-breakpoint-down(lg) {
    --size: 56px;
  }
}

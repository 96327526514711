.s-general-info__body {
  gap: 72px;

  @include media-breakpoint-only(xxl) {
    gap: 48px;
  }

  @include media-breakpoint-down(lg) {
    gap: 48px;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

.s-general-info__content-col {
  @include media-breakpoint-down(md) {
    row-gap: 32px;
    text-align: left;
  }
}

.s-general-info__header {
  justify-content: space-between;
  height: 100%;
  row-gap: 40px;

  @include media-breakpoint-down(md) {
    gap: 40px;
  }
}

.s-general-info__title {
  font-size: 24px;
  line-height: 30px;

  @include media-breakpoint-down(xxl) {
    font-size: 20px;
    line-height: 25px;
  }
}

.s-general-info {
  --padding: 80px;
  --radius: 56px;

  padding: var(--padding);
  border: 1px solid var(--c-secondary);
  border-radius: var(--radius);

  @include media-breakpoint-only(xxl) {
    --padding: 80px;
  }

  @include media-breakpoint-down(lg) {
    --padding: 32px;
    --radius: 40px;
  }
}

.d-semi-circle {
  --width: calc(var(--height) / 2);
  --background: var(--c-secondary);

  height: var(--height, 100%);
  width: var(--width);
  background-color: var(--background);
  border-radius: 0 var(--height) var(--height) 0;

  &.--white {
    --background: var(--c-white);
  }

  &.--black {
    --background: var(--c-text);
  }

  &.--md {
    --height: 52px;
  }

  &.--xl {
    --height: 80px;
  }

  &.--xxl {
    --height: 184px;
  }

  &.--lg {
    --height: 72px;

    @include media-breakpoint-down(xxxl) {
      --height: 64px;
    }

    @include media-breakpoint-down(sm) {
      --height: 56px;
    }
  }
}

.c-hr__visual {
  &::after,
  &::before {
    content: "";
    position: absolute;
    display: block;
    transform: translateY(-50%);
    top: 50%;
    height: 1px;
    width: calc(50% - 24px);
    background-color: var(--c-secondary);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}

.f-text__input {
  background-color: transparent;
  color: var(--font-color);
  padding: var(--padding);
  min-height: var(--min-height);
}

.f-text__input::placeholder {
  color: var(--c-primary);
}

.f-text__icon {
  &.--before,
  &.--after {
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &.--before {
    padding-left: 16px;
    left: 0;
  }

  &.--after {
    padding-right: 16px;
    right: 0;
  }
}

.f-text {
  --border-color: var(--c-text);
  --font-color: var(--c-text);
  --font-size: 20px;
  --padding: 30px 32px;
  --min-height: 87px;

  color: var(--font-color);
  font-size: var(--font-size);

  &.--icon-before {
    .f-text__input {
      padding-left: 48px;
    }
  }

  &.--icon-after {
    .f-text__input {
      padding-right: 48px;
    }
  }

  &.--active {
    --font-color: var(--c-primary);
    --border-color: var(--c-primary);
  }

  &.--invalid {
    --border-color: var(--c-danger);
  }

  @include media-breakpoint-down(xxxl) {
    --font-size: 18px;
    --padding: 22px 24px;
    --min-height: 72px;
  }

  @include media-breakpoint-down(sm) {
    --font-size: 14px;
    --padding: 14px 8px;
    --min-height: 50px;
  }
}

.f-text__wrap {
  border-bottom: 1px solid var(--border-color);
  transition: border-color 0.3s ease;
  will-change: border-color;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 24px;

  right: 16px;
  width: var(--size);
  height: var(--size);

  &.--active {
    --on-opacity: 1;
    --off-opacity: 0;
  }
}

.f-text__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: var(--size);
  height: var(--size);

  &.--on {
    opacity: var(--on-opacity);
  }

  &.--off {
    opacity: var(--off-opacity);
  }
}

.entry-content {
  blockquote {
    font-weight: 400;
    padding: 16px;
    margin-block: 32px;
    position: relative;
    font-style: italic;
    background-color: var(--c-secondary);
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 24px;

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }
}

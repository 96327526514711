.btn__icon {
  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.btn {
  --font-size: 18px;
  --font-weight: 500;
  --font-color: var(--c-button);
  --padding-y: 16px;
  --padding-x: 48px;
  --min-height: 64px;
  --min-width: 200px;
  --border-radius: calc(var(--min-height) / 2);

  font-size: var(--font-size);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  min-width: var(--min-width);
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;

  &:hover {
    text-decoration: none;
    color: var(--font-color);
  }

  &:focus,
  &:active {
    outline: 0;
    color: var(--font-color);
    text-decoration: none;
  }

  &.--icon-before {
    .btn__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-width: auto;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .btn__title {
      display: none;
    }
  }

  &.--icon-only-mobile {
    @include media-breakpoint-down(sm) {
      width: var(--min-height);
      height: var(--min-height);
      min-width: auto;
      min-height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .btn__title {
        display: none;
      }
    }
  }

  &.--full-width-mobile {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.--grow-mobile {
    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }
}

@use "settings/s-fonts.scss" as fonts;

body {
  font-family: fonts.$primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  font-weight: 300;
  color: var(--c-text);
  background-color: var(--c-surface);
  line-height: 1.5;

  &.--dark-theme {
    --c-surface: #000442;
    --c-text: #e7f0ff;
    --c-secondary: #1e236c;
    --c-button: #000442;
    --c-primary: #5673ff;
    --c-switch-surface: #e7f0ff;
    --c-modal-dialog: rgb(0 0 0 / 80%);
  }
}

::selection {
  color: var(--c-surface);
  background: var(--c-primary);
}

.s-mention__img {
  --size: 96px;

  height: var(--size);
  width: var(--size);
}

.s-mention__perex {
  font-size: 24px;
  line-height: 32px;

  @include media-breakpoint-down(xxxl) {
    font-size: 18px;
    line-height: 24px;
  }
}

.g-usps {
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 2fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-usps__cell {
  &.--slide {
    @include media-breakpoint-up(xxl) {
      transition: transform 0.3s ease;
      will-change: transform;
      transform: translateY(40px);
    }
  }
}

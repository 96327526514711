.i-number {
  border-radius: 56px;
}

.i-number__image {
  picture,
  img {
    max-width: 200vw;
    height: 80px;
  }
}

.i-number__title {
  --size: 88px;

  font-size: var(--size);
  line-height: var(--size);

  @include media-breakpoint-down(xxxl) {
    --size: 64px;
  }

  @include media-breakpoint-down(lg) {
    --size: 56px;
  }
}

.i-number__postfix {
  --font-size: 40px;
  --line-height: 56px;

  font-size: var(--font-size);
  line-height: var(--line-height);

  @include media-breakpoint-down(lg) {
    --font-size: 32px;
    --line-height: 40px;
  }
}

.i-number__perex {
  --font-size: 24px;
  --line-height: 30px;

  font-size: var(--font-size);
  line-height: var(--line-height);
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-language-select__drowpdown {
  background-color: var(--c-surface);
  top: 100%;
  left: -1px;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  z-index: 5;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-top: none;
  width: calc(100% + 2px);
}

.c-language-select__active {
  min-height: var(--size);
  min-width: var(--size);
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color 0.3s ease;
  will-change: background-color;
  min-height: var(--size);
  color: var(--c-text);

  &:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }

  &:hover {
    background-color: var(--c-secondary);
    color: var(--c-text);
  }
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  --size: 52px;
  --border-radius: calc(var(--size) / 2);

  border-radius: var(--border-radius);

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;

    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-bottom-color: transparent;
  }

  &.--horizontal {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;

    display: flex;
    flex-direction: row;
    border: none;

    .c-language-select__drowpdown {
      width: auto;
      position: static;
      border: none;
      flex-direction: row;
      background-color: transparent;
    }

    .c-language-select__dropdown-link {
      min-width: var(--size);
    }
  }
}

.g-commendations {
  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    height: auto;
    padding-block: 9px;
    padding-inline: 4px;
  }
}

.g-commendations__icon {
  right: 0;
  bottom: 0;
  transform: translateY(calc(50% + 16px)) translateX(16px);
}

.g-commendations__fraction.swiper-pagination-fraction {
  width: auto;
}

.g-commendations__nav {
  @include media-breakpoint-down(sm) {
    margin-bottom: 24px;
    justify-content: center;
  }
}

.g-commendations__fraction {
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.g-commendations__button {
  transition: opacity 0.3s ease;
  will-change: opacity;

  &.swiper-button-disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

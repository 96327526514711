.s-hero-media__circle-decoration {
  --right: 0;

  right: var(--right);
  top: 0;

  @include media-breakpoint-down(xxxl) {
    width: 240px;
  }

  @include media-breakpoint-down(xxl) {
    width: 192px;
  }

  @include media-breakpoint-down(xl) {
    width: 156px;
  }

  @include media-breakpoint-down(lg) {
    width: 124px;
  }

  @include media-breakpoint-down(md) {
    width: 144px;
  }

  @include media-breakpoint-down(sm) {
    width: 64px;
  }
}

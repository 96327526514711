.i-commendation__hr {
  height: 1px;
}

.i-commendation {
  border-radius: 40px;
  transition: transform 0.3s ease;

  &.--skew-negative {
    @include media-breakpoint-up(lg) {
      transform: rotate(-2deg);
    }
  }

  &.--skew-positive {
    @include media-breakpoint-up(lg) {
      transform: rotate(2deg);
    }
  }

  @include media-breakpoint-down(sm) {
    border-radius: 24px;
  }
}

.i-commendation__description {
  --mh: 140px;

  max-height: var(--mh);
  padding-right: 16px;
  line-height: 24px;

  @include media-breakpoint-down(xxxl) {
    --mh: 172px;

    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    --mh: 220px;

    font-size: 14px;
  }
}

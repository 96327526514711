.img {
  &.--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &.--ratio-16-9 {
    aspect-ratio: 16/9;
  }

  &.--ratio-3-2 {
    aspect-ratio: 3/2;
  }

  &.--ratio-1-1 {
    aspect-ratio: 1/1;
  }

  &.--ratio-3-4 {
    aspect-ratio: 3/4;
  }

  &.--ratio-3-1 {
    aspect-ratio: 3/1;
  }

  &.--contain {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--cover {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--placeholder {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(45deg, var(--c-secondary) 0%, var(--c-surface) 30%, var(--c-secondary) 62%);
      left: 50%;
      top: 50%;
      opacity: 1;
      background-size: 200%;
      animation: placeholder 3s ease-in-out infinite alternate;
      transform: translateX(-50%) translateY(-50%);
      z-index: -1;
    }
  }
}

.img__noise {
  inset: 0;
  z-index: 1;
  mix-blend-mode: soft-light;
  background-repeat: repeat;
  opacity: 0.3;
  image-rendering: crisp-edges;
}

@keyframes placeholder {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

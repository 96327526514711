.i-reference {
  --height: 448px;
  --radius: 40px;
  --opacity: 0;
  --tags-display: none;
  --max-height: 808px;

  @include media-breakpoint-down(xxxl) {
    --height: 336px;
    --max-height: 600px;
  }

  @include media-breakpoint-down(lg) {
    --height: 152px;
    --radius: 24px;
    --max-height: 272px;
  }

  @include media-breakpoint-up(lg) {
    --tags-display: block;
  }

  &.--open {
    /*     --height: var(--max-height); */
    --opacity: 1;
  }

  &.--open-tags {
    --tags-display: block;
    --expand-display: none;
  }

  &:hover {
    color: var(--c-text);
  }
}

.i-reference .i-reference__image {
  height: var(--height);
  border-radius: var(--radius);
  will-change: height;

  img {
    border-radius: var(--radius);
  }
}

.i-reference__tags {
  display: var(--tags-display);
}

.i-reference__image-wrapper {
  height: var(--max-height);
  border-radius: var(--radius);
}

.i-reference__tags,
.i-reference__header,
.i-reference__actions {
  transition: opacity 0.3s ease;
  will-change: opacity;
  opacity: var(--opacity);
}

.i-reference__header {
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}

.i-reference__tags-close {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.i-reference__actions {
  @include media-breakpoint-down(lg) {
    display: var(--expand-display);
  }
}

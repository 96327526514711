$contentBoxShadow: 4px 4px 24px #0f26391a;

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 2rem;
  margin-left: auto;
  margin-right: auto;
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: var(--width);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: var(--content-bg);
  cursor: auto;
  box-shadow: $contentBoxShadow;
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
  border-radius: var(--radius);
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 32px var(--p-inline);
}

.c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--p-block) var(--p-inline) 0;
}

.c-modal__button-container {
  order: -1;
  display: flex;
  justify-content: flex-end;
}

.c-modal__close-cross {
  transform: translate(-16px, 16px);
}

.c-modal__footer {
  padding: 0 var(--p-inline) var(--p-block);

  &.--right {
    justify-content: flex-end;
  }

  &.--center {
    justify-content: center;
  }

  &.--between {
    justify-content: space-between;
  }
}

.c-modal__heading {
  font-size: 22px;
}

.c-modal {
  --transtion-length: 300ms;
  --width: min(100% - 32px, 720px);
  --content-bg: var(--c-white);
  --p-block: 24px;
  --p-inline: 24px;
  --radius: 40px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: var(--c-modal-dialog);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  will-change: opacity;

  &.--open {
    opacity: 1;
    pointer-events: all;
    visibility: visible;

    .c-modal__content {
      transform: translateY(0);
    }
  }

  &.--hidden {
    visibility: hidden;
  }

  &.--size-sm {
    --width: min(100% - 32px, 480px);
  }

  &.--size-lg {
    --width: min(100% - 32px, 976px);
  }

  &.--size-xl {
    --width: min(100% - 32px, 1824px);
    --p-block: 80px;
    --p-inline: 104px;

    @include media-breakpoint-down(xxxl) {
      --p-block: 72px;
      --p-inline: 80px;
      --radius: 32px;
    }

    @include media-breakpoint-down(md) {
      --p-block: 32px;
      --p-inline: 32px;
      --radius: 24px;
    }
  }

  &.--bg-secondary {
    --content-bg: var(--c-secondary);
  }
}

.s-service-intro__header {
  --offset: -220px;

  margin-bottom: var(--offset);

  @include media-breakpoint-down(xxxl) {
    --offset: -200px;
  }

  @include media-breakpoint-down(xxl) {
    --offset: -150px;
  }

  @include media-breakpoint-down(xl) {
    --offset: -120px;
  }

  @include media-breakpoint-down(lg) {
    --offset: -100px;
  }

  @include media-breakpoint-down(md) {
    --offset: -50px;
  }

  @include media-breakpoint-down(sm) {
    --offset: 0;
  }
}

.s-service-intro {
  &.--digi {
    --abstract-left: 0;
  }

  &.--dev {
    --abstract-left: 10%;
  }

  &.--design {
    --abstract-left: 0;
  }

  &.--market {
    --abstract-left: 5%;
  }
}

.s-service-intro__wrap {
  @include media-breakpoint-down(sm) {
    gap: 40px;
  }
}

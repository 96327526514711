.s-contact-form__mask {
  --size: 248px;

  width: var(--size);
  height: var(--size);
  top: -1px;
  right: -1px;
  user-select: none;
  pointer-events: none;

  svg {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(xxxl) {
    --size: 208px;
  }

  @include media-breakpoint-down(xl) {
    --size: 77px;
  }
}

.s-contact-form__radial {
  --size: 432px;

  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);

  @include media-breakpoint-down(xxxl) {
    --size: 368px;
  }

  @include media-breakpoint-down(xl) {
    --size: 90px;
  }
}

.s-contact-form__sun-dial {
  width: 72px;
  height: 72px;
  transform: translateY(-26px);

  svg {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.s-contact-form__abstract {
  @include media-breakpoint-down(xxxl) {
    width: 112px;
  }

  @include media-breakpoint-down(xl) {
    width: 40px;
  }
}

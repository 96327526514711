.d-diamant {
  --square: 92px;

  transition: transform 0.5s ease;
  position: relative;
  margin-right: -16px;

  circle,
  .diamant {
    transition: transform 0.5s ease;
  }

  .diamant {
    transform-origin: center center;
  }

  &:hover {
    circle {
      transform: scale(1.05) translateY(2px);
    }

    .diamant {
      transform: rotate(-15deg) translateX(4px) translateY(-2px);
    }
  }

  svg {
    width: 115%;
    height: 115%;
  }

  @include media-breakpoint-down(xxxl) {
    --square: 84px;
  }

  @include media-breakpoint-down(xxl) {
    --square: 80px;
  }

  @include media-breakpoint-down(lg) {
    --square: 64px;
  }
}

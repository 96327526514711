@use "settings/s-colors" as colors;

.swiper {
  --swiper-pagination-color: var(--c-primary);

  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

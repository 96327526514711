.g-buttons {
  --spacing: 96px;

  margin-top: var(--spacing);

  @include media-breakpoint-down(xxxl) {
    --spacing: 72px;
  }

  @include media-breakpoint-down(md) {
    --spacing: 48px;
  }

  &.--spacing-none {
    --spacing: 0;
  }
}

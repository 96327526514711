.c-cursor {
  box-shadow: var(--shadow-lg);
  border-radius: 50%;
  z-index: 1000;
  opacity: 0;

  &.cursor--initialized {
    display: block;
  }

  &.--focused {
    opacity: 1;
  }
}

.w-base {
  --p: 56px;
  --radius: 56px;

  padding: var(--p);
  border-radius: var(--radius);

  @include media-breakpoint-down(xxxl) {
    --p: 48px;
    --radius: 48px;
  }

  @include media-breakpoint-down(sm) {
    --p: 32px;
    --radius: 40px;
  }
}

.g-testimonials.--infinite {
  margin-top: 64px;

  @include media-breakpoint-down(xxxl) {
    margin-top: 48px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 40px;
  }

  @keyframes scroll {
    to {
      transform: translate(-50%);
    }
  }

  .g-testimonials__inner {
    gap: 120px;
    width: max-content;
    animation: scroll 90s forwards linear infinite;

    @include media-breakpoint-down(xxxl) {
      gap: 96px;
    }

    @include media-breakpoint-down(xl) {
      gap: 56px;
    }

    @include media-breakpoint-down(sm) {
      gap: 48px;
    }
  }

  &.--view-hidden {
    .g-testimonials__inner {
      animation-play-state: paused;
    }
  }
}

@use "settings/s-colors" as colors;

.s-typography__img {
  height: 514px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xl) {
    width: 320px;
    height: 258px;
  }
}

@use "settings/s-colors" as colors;

.s-media__grid {
  display: grid;
  grid-template-columns: 1fr;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-media__item {
  .img {
    border-radius: 56px;
  }
}

.d-radial-gradient {
  background: radial-gradient(circle, var(--background) -40%, transparent 50%);
  width: var(--size, 100%);
  height: var(--size, 100%);
  filter: blur(40px);

  &.--primary-400 {
    --background: var(--c-primary-400);
  }

  &.--primary {
    --background: var(--c-primary);
  }

  &.--tertiary {
    --background: var(--c-tertiary);
  }
}

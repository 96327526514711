@use "settings/s-colors" as colors;

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;

  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: colors.$black;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;
  will-change: opacity;
  position: var(--position);

  &.--show {
    --opacity: 0.75;
    --pointer-events: auto;
  }

  &.--absolute {
    --position: absolute;

    width: 100%;
    height: 100%;
  }
}

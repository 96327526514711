@use "settings/s-colors" as colors;

.s-technologies__title {
  --font-size: 48px;
  --line-height: 56px;

  font-size: var(--font-size);
  line-height: var(--line-height);

  @include media-breakpoint-down(xxxl) {
    --font-size: 32px;
    --line-height: 40px;
  }
}

.s-technologies__img {
  --size: 96px;

  height: var(--size);
  width: var(--size);
}

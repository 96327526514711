.u-max-xxxl {
  display: none;

  @include media-breakpoint-down(xxxl) {
    display: block;

    & ~ * {
      display: none;
    }
  }
}

.u-max-xl {
  display: none;

  @include media-breakpoint-down(xl) {
    display: block;

    & ~ * {
      display: none;
    }
  }
}

.u-max-sm {
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;

    & ~ * {
      display: none;
    }
  }
}

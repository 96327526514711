.u-border {
  border-width: 1px;
  border-style: solid;
}

.u-border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.u-border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.u-border-secondary {
  border-color: var(--c-secondary);
}

:root {
  --c-primary: #264afb;
  --c-primary-400: #5ed7fd;
  --c-conversion: #07eb99;
  --c-conversion-500: #04c38d;
  --c-conversion-600: #009b7f;
  --c-tertiary: #ec84fe;
  --c-text: #00065b;
  --c-text-100: #00065c66;
  --c-secondary: #e7f0ff;
  --c-secondary-100: #e7f0ff4d;
  --c-white: #fff;
  --c-surface: #fff;
  --c-danger: #f82457;
  --c-danger-200: #ec6786;
  --c-success: #009b7f;
  --c-success-200: #e7f4e4;
  --c-warning: #fdb81e;
  --c-warning-200: #fff1d2;
  --c-button: #00065b;
  --c-switch-surface: #fff;
  --c-modal-dialog: rgb(0 0 0 / 25%);
  --shadow-sm: 4px 8px 40px 0px #e7f0ff80;
  --shadow-lg: 8px 8px 24px 0px #07eb991a;
  --shadow-xl: 16px 16px 32px 0px #07eb994d;
  --spacing: 96px;

  @include media-breakpoint-down(xxxl) {
    --spacing: 72px;
  }

  @include media-breakpoint-down(md) {
    --spacing: 48px;
  }
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 48px auto;

  img {
    margin-inline: auto;
  }
}

.alignright {
  float: right;
  margin: 0 0 20px 30px;
}

.alignleft {
  float: left;
  margin: 0 30px 20px 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgb(0 0 0 / 60%);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

@include media-breakpoint-down(sm) {
  .alignleft,
  .alignright {
    float: none;
    display: block;
    margin: 0;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}

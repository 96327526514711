.s-404__title {
  --fz: 320px;

  font-size: var(--fz);

  @include media-breakpoint-down(xxxl) {
    --fz: 240px;
  }

  @include media-breakpoint-down(xxl) {
    --fz: 180px;
  }

  @include media-breakpoint-down(xl) {
    --fz: 140px;
  }

  @include media-breakpoint-down(lg) {
    --fz: 110px;
  }

  @include media-breakpoint-down(md) {
    --fz: 200px;

    line-height: 200px;
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    --fz: 160px;

    line-height: 160px;
  }
}

.i-usp {
  border-radius: 56px;

  @include media-breakpoint-only(xxl) {
    padding: 32px;
    padding-block: 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 32px;
    border-radius: 40px;
  }

  path {
    fill: var(--c-text);
  }
}

.i-usp__icon {
  @include media-breakpoint-down(sm) {
    --square: 32px;
  }
}

.i-usp__heading {
  --fz: 24px;

  font-size: var(--fz);

  @include media-breakpoint-down(sm) {
    --fz: 18px;
  }
}

.i-usp__description {
  @include media-breakpoint-down(xxxl) {
    font-size: 16px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.i-usp__body {
  @include media-breakpoint-down(sm) {
    gap: 8px;
  }
}

.s-404__description-grid {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }
}

.s-404__description {
  max-width: 100%;
  font-size: 16px;
  line-height: 20px;

  @include media-breakpoint-up(sm) {
    font-size: 20px;
    line-height: 25px;
  }
}

.s-hero__mask {
  --ratio: 38/15;

  img {
    aspect-ratio: var(--ratio);
  }

  @include media-breakpoint-down(xxxl) {
    --ratio: 170/81;
  }

  @include media-breakpoint-down(sm) {
    --ratio: 19/16;
  }
}

.s-hero__heading {
  --fz: 136px;
  --lh: 1;

  max-width: 1300px;
  font-size: var(--fz);
  line-height: var(--lh);

  @include media-breakpoint-down(xxxl) {
    --fz: 112px;
  }

  @include media-breakpoint-down(xl) {
    --fz: 80px;
  }

  @include media-breakpoint-down(lg) {
    --fz: 60px;
    --lh: 1.2;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  @include media-breakpoint-down(sm) {
    --fz: 48px;
    --lh: 1;
  }
}

.s-hero__image {
  margin-top: 40px;

  @include media-breakpoint-up(sm) {
    margin-top: 80px;
  }

  @include media-breakpoint-between(xl, xxl) {
    margin-top: 140px;
  }

  @include media-breakpoint-between(lg, xl) {
    margin-top: 120px;
  }

  @include media-breakpoint-between(md, lg) {
    margin-top: 110px;
  }

  @include media-breakpoint-between(sm, md) {
    margin-top: 250px;
  }
}

.s-hero__wrap {
  display: grid;

  @include media-breakpoint-up(sm) {
    .s-hero__header,
    .s-hero__image {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }
}

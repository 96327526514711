.c-circle-text-play {
  --square: 160px;

  animation: spin 10s linear infinite;
  animation-play-state: paused;

  @media (hover: hover) {
    &:hover {
      animation-play-state: running;
    }
  }

  @include media-breakpoint-down(md) {
    --square: 120px;
  }

  @include media-breakpoint-down(sm) {
    --square: 88px;
  }
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: -360deg;
  }
}

.g-partners__decoration {
  height: var(--decoration-size);
}

.g-partners__radial {
  --size: 272px;

  right: 0;
  bottom: 0;
  transform: translateX(30%) translateY(30%);

  @include media-breakpoint-down(xxl) {
    --size: 240px;
  }
}

.g-partners__row {
  --semi-circle-size: 208px;
  --decoration-size: 208px;

  @include media-breakpoint-down(xxxl) {
    --decoration-size: 176px;
    --semi-circle-size: 176px;
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(2, 1fr);
  }

  &:nth-child(odd) {
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 1fr 1fr var(--decoration-size);
    }
  }

  &:nth-child(even) {
    @include media-breakpoint-up(xxl) {
      grid-template-columns: 104px 1fr 1fr;
    }
  }

  &:not(:first-child) {
    .g-partners__decoration {
      border: 1px solid var(--c-secondary);
      border-radius: 50%;

      img {
        display: none;
      }
    }

    .g-partners__radial {
      display: none;
    }
  }
}

.g-partners__semi-circle {
  --height: var(--semi-circle-size);
}

.base-header {
  --spacing: 96px;

  margin-inline: auto;
  max-width: 1080px;
  margin-bottom: var(--spacing);

  @include media-breakpoint-down(xxxl) {
    --spacing: 72px;
  }

  @include media-breakpoint-down(md) {
    --spacing: 48px;
  }

  &.--left {
    align-items: flex-start;
    text-align: left;
    margin-inline: 0;
  }

  &.--spacing-none {
    --spacing: 0;
  }
}

.base-header__description {
  p {
    margin-bottom: 0;
  }

  a {
    font-weight: 500;
    color: var(--c-text);
  }

  max-width: 686px;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

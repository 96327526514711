.s-hero__badge {
  --offset: 48px;
  --svg-size: 184px;

  bottom: var(--offset);
  right: var(--offset);

  svg {
    width: var(--svg-size);
    height: var(--svg-size);
  }

  @include media-breakpoint-down(xxxl) {
    --offset: 40px;
  }

  @include media-breakpoint-down(xxl) {
    --svg-size: 168px;
    --offset: 32px;
  }

  @include media-breakpoint-down(xl) {
    --offset: 32px;
    --svg-size: 128px;

    gap: 16px;
  }

  @include media-breakpoint-down(lg) {
    --offset: 16px;
    --svg-size: 96px;
  }

  @include media-breakpoint-down(md) {
    --svg-size: 56px;

    gap: 8px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.s-hero__semi-circle {
  --height: var(--svg-size);
}

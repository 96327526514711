.base-box {
  border-radius: 24px;

  &.--lg {
    @include media-breakpoint-up(xl) {
      padding: 72px 80px;
      border-radius: 32px;
    }

    @include media-breakpoint-up(xxxl) {
      padding: 80px 104px;
      border-radius: 40px;
    }
  }
}

.s-hero-media__reference-decoration {
  --right: 0;

  right: var(--right);
  top: 0;

  .icon {
    --size: 336px;

    @include media-breakpoint-down(xxxl) {
      --size: 270px;
    }

    @include media-breakpoint-down(xxl) {
      --size: 215px;
    }

    @include media-breakpoint-down(xl) {
      --size: 180px;
    }

    @include media-breakpoint-down(lg) {
      --size: 130px;
    }

    @include media-breakpoint-down(md) {
      --size: 95px;
    }

    @include media-breakpoint-down(sm) {
      --size: 64px;
    }
  }

  &[style] {
    .icon {
      path {
        fill: currentcolor;
      }
    }
  }
}

.s-author-header__button {
  margin-bottom: var(--spacing);
}

.s-author-header__image-col {
  --size: 352px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  padding: 40px;

  @include media-breakpoint-only(xxl) {
    --size: 300px;
  }

  @include media-breakpoint-down(lg) {
    --size: 250px;

    padding: 32px;
  }

  @include media-breakpoint-down(sm) {
    --size: 208px;

    padding: 24px;
  }
}

.s-author-header__image {
  border-radius: 50%;
}

.s-author-header__description {
  --fz: 20px;
  --lh: 24px;

  font-size: var(--fz);
  line-height: var(--lh);

  @include media-breakpoint-down(sm) {
    --fz: 16px;
  }
}

.s-author-header__rank {
  --fz: 18px;

  font-size: var(--fz);

  @include media-breakpoint-down(sm) {
    --fz: 16px;
  }
}

.s-author-header__body-col {
  --gap: 72px;

  gap: var(--gap);

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: start;
  }

  @include media-breakpoint-down(sm) {
    --gap: 24px;
  }
}

.s-author-header__content {
  @include media-breakpoint-down(sm) {
    gap: 24px;
  }
}

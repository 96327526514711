.s-specialist__image-col {
  --size: 352px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  @include media-breakpoint-only(xxl) {
    --size: 300px;
  }

  @include media-breakpoint-down(lg) {
    --size: 250px;

    padding: 32px;
  }

  @include media-breakpoint-down(sm) {
    --size: 208px;

    padding: 24px;
  }
}

.s-specialist__image {
  border-radius: 50%;
}

.s-specialist__body {
  gap: 72px;

  @include media-breakpoint-only(xxl) {
    gap: 48px;
  }

  @include media-breakpoint-down(lg) {
    gap: 48px;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

.s-specialist__content-col {
  @include media-breakpoint-down(md) {
    align-items: center;
    row-gap: 32px;
    text-align: center;
  }
}

.s-specialist {
  --padding: 71px;
  --radius: 56px;

  padding: var(--padding);
  border-radius: var(--radius);

  @include media-breakpoint-only(xxl) {
    --padding: 32px;
  }

  @include media-breakpoint-down(lg) {
    --padding: 32px;
    --radius: 40px;
  }
}

.s-specialist__rank {
  --fz: 18px;

  font-size: var(--fz);

  @include media-breakpoint-down(xxxl) {
    --fz: 16px;
  }
}

.main-footer-badge__radial {
  --size: 200px;

  @include media-breakpoint-up(xxl) {
    left: calc(50% - 48px);
    top: calc(50% - 8px);
    transform: translateX(-50%) translateY(-50%);
  }

  @include media-breakpoint-down(xxl) {
    left: calc(50% - 8px);
    bottom: 24px;
    transform: translateX(-50%) translateY(50%);
  }

  @include media-breakpoint-down(sm) {
    --size: 136px;
  }
}

.main-footer-badge {
  @include media-breakpoint-down(xxl) {
    flex-direction: row;
    align-items: self-start;
  }

  @include media-breakpoint-down(sm) {
    svg {
      height: 144px;
      width: 144px;
    }
  }
}

.main-footer-badge__semicircle {
  @include media-breakpoint-down(xxl) {
    width: 92px;
    height: 184px;
  }

  @include media-breakpoint-down(sm) {
    width: 64px;
    height: 144px;
  }
}

// Fontweights by project needs

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.w-author-contact {
  --p: 48px;
  --radius: 56px;
  --gap: 48px;

  padding: var(--p);
  border-radius: var(--radius);
  gap: var(--gap);
  max-width: 432px;

  @include media-breakpoint-down(sm) {
    --p: 32px;
    --gap: 32px;
    --radius: 40px;
  }
}

.w-author-contact__hr {
  height: 1px;
}

.w-author-contact__abstract {
  right: 0;
  top: 0;
  transform: translateX(25%) translateY(-25%);
}

.w-author-contact__radial {
  right: 0;
  top: 0;
  transform: translateX(30%) translateY(-30%);
}

@use "settings/s-colors" as colors;

.i-person {
  padding: 48px;
  border-radius: 56px;
  border: 1px solid var(--c-secondary);
}

.i-person__image-col {
  --size: 208px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  @include media-breakpoint-down(lg) {
    --size: 208px;

    padding: 32px;
  }

  @include media-breakpoint-down(sm) {
    --size: 224px;

    padding: 24px;
  }
}

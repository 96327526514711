.i-pill {
  --inline: 40px;
  --radius: 24px;
  --height: 80px;

  padding-inline: var(--inline);
  padding-block: 8px;
  border-radius: var(--radius);
  min-height: var(--height);
  color: var(--c-button);

  @include media-breakpoint-down(md) {
    --inline: 32px;
    --height: 72px;
  }
}

.i-pill__icon {
  --size: 24px;

  height: var(--size);
  max-width: var(--size);
  flex: 0 0 var(--size);
}

body.--dark-theme .i-pill {
  background-color: var(--c-text);
}

.i-partner {
  border-radius: 104px;
  min-height: 208px;

  @include media-breakpoint-down(xxxl) {
    min-height: 176px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    border-radius: 88px 88px 40px 40px;
    text-align: center;
    align-items: center;
  }
}

.i-partner__image {
  --width: 208px;
  --height: 160px;
  --radius: calc(var(--height) / 2);

  max-width: var(--width);
  flex: 0 0 var(--width);
  height: var(--height);
  border-radius: var(--radius);

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 88px;
  }

  @include media-breakpoint-down(xxxl) {
    --width: 166px;
    --height: 128px;
  }

  @include media-breakpoint-down(sm) {
    --width: 100%;

    width: 100%;
    flex: auto;
  }
}

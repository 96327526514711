[class*="u-square-"],
.u-square {
  width: var(--square);
  height: var(--square);
}

.u-square-1 {
  --square: 8px;
}

.u-square-2 {
  --square: 16px;
}

.u-square-3 {
  --square: 24px;
}

.u-square-4 {
  --square: 32px;
}

.u-square-5 {
  --square: 40px;
}

.u-square-6 {
  --square: 48px;
}

.btn {
  &.--xxs {
    --font-size: 16px;
    --padding-y: 2px;
    --padding-x: 8px;
    --min-height: 48px;
  }

  &.--xs {
    --font-size: 16px;
    --padding-y: 4px;
    --padding-x: 24px;
    --min-height: 52px;
  }

  &.--sm {
    --font-size: 16px;
    --padding-y: 4px;
    --min-height: 56px;
  }

  &.--md {
    --font-size: 18px;
    --min-height: 64px;
    --padding-x: 32px;
    --padding-y: 8px;

    @include media-breakpoint-down(sm) {
      --font-size: 16px;
      --min-height: 56px;
      --padding-x: 24px;
      --padding-y: 4px;
    }
  }

  &.--lg {
    --font-size: 20px;
    --min-height: 72px;
    --padding-y: 12px;

    @include media-breakpoint-down(xxxl) {
      --font-size: 18px;
      --min-height: 64px;
      --padding-y: 8px;
    }

    @include media-breakpoint-down(sm) {
      --font-size: 16px;
      --min-height: 56px;
      --padding-x: 24px;
      --padding-y: 4px;
    }
  }

  &.--xl {
    --font-size: 20px;
    --min-height: 80px;

    @include media-breakpoint-down(xxxl) {
      --min-height: 72px;
    }

    @include media-breakpoint-down(sm) {
      --min-height: 56px;
    }
  }
}

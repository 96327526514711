.i-date {
  @include media-breakpoint-down(xxxl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    gap: 4px;
  }

  &.--size-l {
    --fz: 18px;

    font-size: var(--fz);

    @include media-breakpoint-down(xxxl) {
      --fz: 16px;
    }

    @include media-breakpoint-down(sm) {
      --fz: 14px;
    }
  }

  &[style] {
    .icon {
      path {
        fill: currentcolor;
      }
    }
  }
}

@use "settings/s-colors.scss" as colors;

.i-tag {
  --font-color: var(--c-primary);
  --font-size: 14px;
  --background-color: var(--c-surface);
  --min-height: 32px;
  --border-radius: calc(var(--min-height) / 2);

  background-color: var(--background-color);
  color: var(--font-color);
  border: 1px solid var(--background-color);
  min-height: var(--min-height);
  border-radius: var(--border-radius);
  font-size: var(--font-size);

  &.--blank {
    --background-color: transparent;

    padding: 0;
    min-height: auto;
  }

  &.--icon-before {
    .i-tag__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-height: auto;
    padding: 0;

    .i-tag__title {
      display: none;
    }
  }

  &.--input {
    transition-property: border-color, background-color;
    will-change: border-color, background-color;
  }

  &.--secondary {
    --background-color: var(--c-secondary);
  }

  &.--outline {
    border-color: var(--c-secondary);
  }
}

label.i-tag,
a.i-tag,
.i-tag.--hoverable {
  text-decoration: none;
  transition: border-color 0.3s ease;
  will-change: border-color;
  cursor: pointer;

  &:hover {
    border-color: var(--c-primary);
  }
}

a.i-tag.--blank {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
  will-change: text-decoration-color;

  &:hover {
    border-color: transparent;
    text-decoration-color: var(--font-color);
  }
}

.i-tag__input {
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
}

// Firefox finaly support this
.i-tag:has(.i-tag__input:checked) {
  --font-color: var(--c-white);
  --background-color: var(--c-primary);
}

.g-pills__cell {
  --skew: 0;

  transition:
    rotate 0.5s cubic-bezier(0.68, -0.55, 0.27, 3),
    margin-bottom 0.5s ease;
  will-change: rotate, margin-bottom;

  &.--skew {
    --skew: -6deg;

    rotate: var(--skew);

    &:nth-child(even) {
      --skew: 6deg;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }
}

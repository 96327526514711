@use "settings/s-colors.scss" as colors;

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --font-size: 20px;
  --left: 32px;

  position: absolute;
  left: var(--left);
  top: var(--top);
  transform: translateY(var(--translate-y));
  font-size: var(--font-size);
  transition: transform 0.3s ease;
  transition-property: transform, font-size, top;
  will-change: transform;

  @include media-breakpoint-down(xxxl) {
    --font-size: 18px;
    --left: 24px;
  }

  @include media-breakpoint-down(sm) {
    --font-size: 14px;
    --left: 8px;
  }

  &.--required {
    &::after {
      content: "*";
      padding-left: 4px;
    }
  }

  &.--active {
    --top: 0;
    --translate-y: 0;
    --font-size: 14px;

    @include media-breakpoint-down(sm) {
      --translate-y: -50%;
    }
  }
}

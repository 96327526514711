.i-color {
  margin-right: -214px;

  @include media-breakpoint-down(xl) {
    margin-right: -107px;
  }

  @include media-breakpoint-down(sm) {
    margin-right: -94px;
  }
}

.i-color__item {
  width: 280px;
  height: 448px;
  display: flex;
  padding: 20px;
  align-items: start;
  border-radius: 32px;
  font-weight: 400;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down(xl) {
    width: 168px;
    height: 224px;
  }

  @include media-breakpoint-down(sm) {
    width: 136px;
    border-radius: 16px;
  }

  .i-color__title {
    font-size: 32px;

    @include media-breakpoint-down(xl) {
      font-size: 16px;
    }
  }

  .i-color__bottom-text {
    font-size: 96px;
    align-self: end;

    @include media-breakpoint-down(xl) {
      font-size: 48px;
    }
  }

  &:not(:hover) {
    .i-color__title,
    .i-color__bottom-text {
      opacity: 0;
    }
  }

  &:hover,
  &.--last-not-hovered {
    .i-color__title,
    .i-color__bottom-text {
      opacity: 1;
    }
  }
}

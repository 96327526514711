.s-share__body {
  --px: 48px;
  --py: 40px;

  min-height: 112px;
  padding-inline: var(--px);
  padding-block: var(--py);
  border-radius: 56px;

  @include media-breakpoint-down(sm) {
    --px: 24px;
    --py: 24px;
  }
}

.s-share__abstract {
  --square: 112px;
}

.s-share__radial {
  --size: 216px;

  right: 0;
  bottom: 0;
  transform: translateX(40%) translateY(35%);
}

.s-share__eye {
  top: 0;
  right: 0;
  transform: translateX(25%) translateY(-35%);
}

.s-share__list {
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

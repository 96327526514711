.i-textation__order {
  width: 140px;

  &::before,
  &::after {
    content: attr(data-symbol);
    user-select: none;
    display: inline-block;
  }

  &::after {
    transform: rotate(180deg);
  }
}

.i-quote__hr {
  height: 1px;
  width: 100%;
  margin-top: 16px;
}

.i-quote {
  max-width: 1205px;

  @include media-breakpoint-down(sm) {
    gap: 24px;
    padding-inline: 16px;
  }
}

.i-quote__description {
  --font-size: 40px;
  --line-height: 64px;

  line-height: var(--line-height);
  font-size: var(--font-size);

  @include media-breakpoint-down(xxxl) {
    --font-size: 32px;
  }

  @include media-breakpoint-down(xl) {
    --font-size: 24px;
    --line-height: 48px;
  }

  @include media-breakpoint-down(sm) {
    --font-size: 18px;
    --line-height: 28px;
  }
}

.i-quote__icon {
  @include media-breakpoint-down(sm) {
    width: 40px;
  }
}

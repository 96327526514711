.i-alert {
  --background: var(--c-surface);
  --color: var(--c-text);
  --opacity: 0;
  --translate: -32px;
  --fz: 24px;
  --p: 32px;
  --radius: 40px;
  --icon-size: 64px;

  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  transform: translateY(var(--translate));
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  font-size: var(--fz);
  padding: var(--p);
  border-radius: var(--radius);
  box-shadow: var(--shadow-sm);

  &.--show {
    --opacity: 1;
    --translate: 0;

    pointer-events: auto;
  }

  &.--success {
    --color: var(--c-success);
  }

  &.--danger {
    --color: var(--c-danger);
  }

  @include media-breakpoint-down(xxxl) {
    --fz: 18px;
    --radius: 32px;
    --p: 24px;
    --icon-size: 56px;
  }

  @include media-breakpoint-down(md) {
    --fz: 16px;
    --radius: 24px;
    --p: 24px;
    --icon-size: 32px;
  }

  @include media-breakpoint-down(sm) {
    --fz: 14px;
    --p: 16px;
  }
}

.i-alert__icon {
  --square: var(--icon-size);

  border-radius: 64px;

  svg {
    width: 100%;
    height: 100%;
  }
}

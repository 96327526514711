.c-overline {
  --fz: 18px;

  gap: 2px;
  font-size: var(--fz);

  &::before,
  &::after {
    content: attr(data-symbol);
    user-select: none;
  }

  &::after {
    transform: rotate(180deg);
  }

  @include media-breakpoint-down(xxxl) {
    --fz: 16px;
  }

  @include media-breakpoint-down(md) {
    --fz: 14px;
  }
}

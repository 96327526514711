.u-bg-primary {
  background-color: var(--c-primary);
}

.u-bg-secondary {
  background-color: var(--c-secondary);
}

.u-bg-white {
  background-color: var(--c-white);
}

.u-bg-surface {
  background-color: var(--c-surface);
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (min-width: 90em) {
  .container {
    max-width: 86rem;
  }
}

@media (min-width: 120em) {
  .container {
    max-width: 117rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 90rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-12 {
  flex: none;
  width: 100%;
}

.g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.gy-2 {
  --bs-gutter-y: 1rem;
}

.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

.gy-5 {
  --bs-gutter-y: 2.5rem;
}

@media (min-width: 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (min-width: 62em) {
  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (min-width: 75em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }
}

@media (min-width: 90em) {
  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (min-width: 120em) {
  .col-xxxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxxl-9 {
    flex: none;
    width: 75%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.opacity-100 {
  opacity: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.position-fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.end-0 {
  right: 0;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.m-0 {
  margin: 0;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 3rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-2 {
  margin-right: 1rem;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-6 {
  padding: 3rem;
}

.p-7 {
  padding: 3.5rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-7 {
  padding-top: 3.5rem;
}

.pe-4 {
  padding-right: 2rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.ps-4 {
  padding-left: 2rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.row-gap-1 {
  row-gap: .5rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1.5rem;
}

.row-gap-4 {
  row-gap: 2rem;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-nowrap {
  white-space: nowrap;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 36em) {
  .d-sm-block {
    display: block;
  }

  .d-sm-none {
    display: none;
  }

  .mt-sm-2 {
    margin-top: 1rem;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem;
  }

  .mb-sm-7 {
    margin-bottom: 3.5rem;
  }

  .p-sm-2 {
    padding: 1rem;
  }

  .px-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-sm-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-sm-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-sm-6 {
    padding-top: 3rem;
  }

  .pe-sm-6 {
    padding-right: 3rem;
  }

  .pb-sm-6 {
    padding-bottom: 3rem;
  }

  .ps-sm-6 {
    padding-left: 3rem;
  }

  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: 48em) {
  .d-md-block {
    display: block;
  }

  .d-md-inline-flex {
    display: inline-flex;
  }

  .d-md-none {
    display: none;
  }

  .mt-md-3 {
    margin-top: 1.5rem;
  }

  .py-md-6 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-md-4 {
    gap: 2rem;
  }
}

@media (min-width: 62em) {
  .d-lg-grid {
    display: grid;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .align-items-lg-start {
    align-items: flex-start;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .mb-lg-0 {
    margin-bottom: 0;
  }

  .p-lg-4 {
    padding: 2rem;
  }
}

@media (min-width: 75em) {
  .d-xl-block {
    display: block;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-none {
    display: none;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .pe-xl-0 {
    padding-right: 0;
  }
}

@media (min-width: 90em) {
  .d-xxl-block {
    display: block;
  }

  .px-xxl-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 120em) {
  .mb-xxxl-2 {
    margin-bottom: 1rem;
  }

  .p-xxxl-5 {
    padding: 2.5rem;
  }
}

.container {
  --padding-inline: 1.5rem;
  padding-right: var(--padding-inline);
  padding-left: var(--padding-inline);
}

@media (min-width: 120em) {
  .container {
    --padding-inline: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .container {
    --padding-inline: 1rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: var(--c-primary);
}

.flatpickr-calendar {
  --theme-color: var(--c-conversion);
  --theme-color-hover: var(--c-conversion-500);
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: var(--c-primary);
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  color: var(--c-text);
  background-color: var(--c-surface);
  flex-direction: column;
  min-height: 100%;
  font-family: Geologica, tahoma;
  font-weight: 300;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

body.--dark-theme {
  --c-surface: #000442;
  --c-text: #e7f0ff;
  --c-secondary: #1e236c;
  --c-button: #000442;
  --c-primary: #5673ff;
  --c-switch-surface: #e7f0ff;
  --c-modal-dialog: #000c;
}

::selection {
  color: var(--c-surface);
  background: var(--c-primary);
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

html {
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: var(--c-primary);
  will-change: text-decoration-color;
  text-decoration: underline;
  transition: text-decoration-color .3s;
}

a:hover, a:focus {
  color: var(--c-primary);
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow: hidden;
}

:root {
  --c-primary: #264afb;
  --c-primary-400: #5ed7fd;
  --c-conversion: #07eb99;
  --c-conversion-500: #04c38d;
  --c-conversion-600: #009b7f;
  --c-tertiary: #ec84fe;
  --c-text: #00065b;
  --c-text-100: #00065c66;
  --c-secondary: #e7f0ff;
  --c-secondary-100: #e7f0ff4d;
  --c-white: #fff;
  --c-surface: #fff;
  --c-danger: #f82457;
  --c-danger-200: #ec6786;
  --c-success: #009b7f;
  --c-success-200: #e7f4e4;
  --c-warning: #fdb81e;
  --c-warning-200: #fff1d2;
  --c-button: #00065b;
  --c-switch-surface: #fff;
  --c-modal-dialog: #00000040;
  --shadow-sm: .25rem .5rem 2.5rem 0 #e7f0ff80;
  --shadow-lg: .5rem .5rem 1.5rem 0 #07eb991a;
  --shadow-xl: 1rem 1rem 2rem 0 #07eb994d;
  --spacing: 6rem;
}

@media (max-width: 119.999em) {
  :root {
    --spacing: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  :root {
    --spacing: 3rem;
  }
}

strong, b {
  font-weight: 600;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (max-width: 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.aligncenter, div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.aligncenter img, div.aligncenter img {
  margin-inline: auto;
}

.alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

@media (max-width: 35.999em) {
  .alignleft, .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.u-bg-primary {
  background-color: var(--c-primary);
}

.u-bg-secondary {
  background-color: var(--c-secondary);
}

.u-bg-white {
  background-color: var(--c-white);
}

.u-bg-surface {
  background-color: var(--c-surface);
}

body:not(.--dark-theme) .u-mix-blend-multiply {
  mix-blend-mode: multiply;
}

.u-border {
  border-style: solid;
  border-width: .0625rem;
}

.u-border-bottom {
  border-bottom-style: solid;
  border-bottom-width: .0625rem;
}

.u-border-top {
  border-top-style: solid;
  border-top-width: .0625rem;
}

.u-border-secondary {
  border-color: var(--c-secondary);
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: var(--c-primary);
}

.u-color-text {
  color: var(--c-text);
}

.u-color-text-100 {
  color: var(--c-text-100);
}

.u-color-conversion {
  color: var(--c-conversion);
}

.u-color-conversion-600 {
  color: var(--c-conversion-600);
}

.u-color-white {
  color: var(--c-white);
}

.u-container-inline {
  container-type: inline-size;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-double-border-fix {
  margin-top: -.0625rem;
  margin-right: -.0625rem;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
  line-height: 1rem;
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
  line-height: 1.5rem;
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
}

.u-fz-xxl {
  --size: 1.5rem;
  font-size: var(--size);
}

@media (max-width: 35.999em) {
  .u-fz-xxl {
    font-size: 1.125rem;
  }
}

.u-fw-300 {
  font-weight: 300;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-h1 {
  --size: 8.5rem;
  --line-height: 8.5rem;
}

@media (max-width: 119.999em) {
  .u-h1 {
    --size: 7rem;
    --line-height: 7.5rem;
  }
}

@media (max-width: 74.999em) {
  .u-h1 {
    --size: 6rem;
    --line-height: 6rem;
  }
}

@media (max-width: 61.999em) {
  .u-h1 {
    --size: 4.5rem;
    --line-height: 5rem;
  }
}

@media (max-width: 35.999em) {
  .u-h1 {
    --size: 2.5rem;
    --line-height: 3rem;
  }
}

.u-h2 {
  --size: 4rem;
  --line-height: 5rem;
}

@media (max-width: 119.999em) {
  .u-h2 {
    --size: 3.5rem;
    --line-height: 4rem;
  }
}

@media (max-width: 61.999em) {
  .u-h2 {
    --size: 1.5rem;
    --line-height: 2rem;
  }
}

.u-h3 {
  --size: 2rem;
  --line-height: 2.5rem;
}

@media (max-width: 119.999em) {
  .u-h3 {
    --size: 1.5rem;
    --line-height: 1.875rem;
  }
}

@media (max-width: 61.999em) {
  .u-h3 {
    --size: 1.25rem;
    --line-height: 1.5rem;
  }
}

.u-h4 {
  --size: 1.25rem;
  --line-height: 1.5rem;
}

@media (max-width: 61.999em) {
  .u-h4 {
    --size: 1.125rem;
  }
}

.u-h5 {
  --size: 1.125rem;
  --line-height: 1.375rem;
}

@media (max-width: 61.999em) {
  .u-h5 {
    --size: 1rem;
    --line-height: 1.125rem;
  }
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  position: relative;
}

.--loading:before {
  content: "";
  background-color: var(--c-white);
  z-index: 9000;
  will-change: opacity;
  opacity: .3;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  animation: 2s ease-out infinite opacity-pulse;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  border: .25rem solid var(--c-conversion);
  border-bottom-color: var(--c-conversion-600);
  z-index: 9001;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: .3;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: .3;
  }
}

.u-mask {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: var(--c-primary);
  --os-handle-bg-hover: var(--c-primary);
  --os-handle-bg-active: var(--c-primary);
  --os-handle-border-radius: .25rem;
  --os-size: .5rem;
  --os-track-bg: var(--c-secondary);
  --os-track-bg-hover: var(--c-secondary);
  --os-track-bg-active: var(--c-secondary);
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-1\/2 {
  top: 50%;
}

.u-left-1\/2 {
  left: 50%;
}

.u-left-0 {
  left: 0;
}

.u-translate-x-1\/2 {
  transform: translateX(-50%);
}

.u-translate-y-1\/2 {
  transform: translateY(-50%);
}

.u-translate-1\/2 {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-pb-spacing {
  padding-bottom: var(--spacing);
}

[class*="u-square-"], .u-square {
  width: var(--square);
  height: var(--square);
}

.u-square-1 {
  --square: .5rem;
}

.u-square-2 {
  --square: 1rem;
}

.u-square-3 {
  --square: 1.5rem;
}

.u-square-4 {
  --square: 2rem;
}

.u-square-5 {
  --square: 2.5rem;
}

.u-square-6 {
  --square: 3rem;
}

.u-max-xxxl {
  display: none;
}

@media (max-width: 119.999em) {
  .u-max-xxxl {
    display: block;
  }

  .u-max-xxxl ~ * {
    display: none;
  }
}

.u-max-xl {
  display: none;
}

@media (max-width: 74.999em) {
  .u-max-xl {
    display: block;
  }

  .u-max-xl ~ * {
    display: none;
  }
}

.u-max-sm {
  display: none;
}

@media (max-width: 35.999em) {
  .u-max-sm {
    display: block;
  }

  .u-max-sm ~ * {
    display: none;
  }
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-3 {
  z-index: 3;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.c-404-pill {
  --height: 14rem;
  --radius: 25rem;
  --rotate: 0deg;
  height: var(--height);
  border-radius: var(--radius);
  aspect-ratio: 5 / 2;
}

@media (max-width: 119.999em) {
  .c-404-pill {
    --height: 9.375rem;
  }
}

@media (max-width: 89.999em) {
  .c-404-pill {
    --height: 7.5rem;
  }
}

@media (max-width: 61.999em) {
  .c-404-pill {
    --height: 5.5rem;
  }
}

@media (max-width: 47.999em) {
  .c-404-pill {
    --height: 7.5rem;
  }
}

.c-404-pill:hover {
  --rotate: 30deg;
}

.c-404-pill__inner {
  will-change: transform;
  transform: translateX(-50%) translateY(-50%) rotate(var(--rotate));
  transition: transform 1s;
}

.base-box {
  border-radius: 1.5rem;
}

@media (min-width: 75em) {
  .base-box.--lg {
    border-radius: 2rem;
    padding: 4.5rem 5rem;
  }
}

@media (min-width: 120em) {
  .base-box.--lg {
    border-radius: 2.5rem;
    padding: 5rem 6.5rem;
  }
}

.base-header {
  --spacing: 6rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 67.5rem;
}

@media (max-width: 119.999em) {
  .base-header {
    --spacing: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .base-header {
    --spacing: 3rem;
  }
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header__description {
  max-width: 42.875rem;
}

.base-header__description p {
  margin-bottom: 0;
}

.base-header__description a {
  color: var(--c-text);
  font-weight: 500;
}

@media (max-width: 35.999em) {
  .base-header__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.c-overline {
  --fz: 1.125rem;
  font-size: var(--fz);
  gap: .125rem;
}

.c-overline:before, .c-overline:after {
  content: attr(data-symbol);
  -webkit-user-select: none;
  user-select: none;
}

.c-overline:after {
  transform: rotate(180deg);
}

@media (max-width: 119.999em) {
  .c-overline {
    --fz: 1rem;
  }
}

@media (max-width: 47.999em) {
  .c-overline {
    --fz: .875rem;
  }
}

.base-section {
  --padding-y: 6rem;
  padding-block: var(--padding-y);
}

@media (max-width: 119.999em) {
  .base-section {
    --padding-y: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .base-section {
    --padding-y: 2.5rem;
  }
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--none {
  --padding-y: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    will-change: transform;
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    will-change: transform;
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    will-change: transform;
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
    will-change: transform;
  }
}

.btn.--xxs {
  --font-size: 1rem;
  --padding-y: .125rem;
  --padding-x: .5rem;
  --min-height: 3rem;
}

.btn.--xs {
  --font-size: 1rem;
  --padding-y: .25rem;
  --padding-x: 1.5rem;
  --min-height: 3.25rem;
}

.btn.--sm {
  --font-size: 1rem;
  --padding-y: .25rem;
  --min-height: 3.5rem;
}

.btn.--md {
  --font-size: 1.125rem;
  --min-height: 4rem;
  --padding-x: 2rem;
  --padding-y: .5rem;
}

@media (max-width: 35.999em) {
  .btn.--md {
    --font-size: 1rem;
    --min-height: 3.5rem;
    --padding-x: 1.5rem;
    --padding-y: .25rem;
  }
}

.btn.--lg {
  --font-size: 1.25rem;
  --min-height: 4.5rem;
  --padding-y: .75rem;
}

@media (max-width: 119.999em) {
  .btn.--lg {
    --font-size: 1.125rem;
    --min-height: 4rem;
    --padding-y: .5rem;
  }
}

@media (max-width: 35.999em) {
  .btn.--lg {
    --font-size: 1rem;
    --min-height: 3.5rem;
    --padding-x: 1.5rem;
    --padding-y: .25rem;
  }
}

.btn.--xl {
  --font-size: 1.25rem;
  --min-height: 5rem;
}

@media (max-width: 119.999em) {
  .btn.--xl {
    --min-height: 4.5rem;
  }
}

@media (max-width: 35.999em) {
  .btn.--xl {
    --min-height: 3.5rem;
  }
}

.btn.--primary {
  --background: var(--c-conversion);
  --box-shadow: var(--shadow-lg);
  --box-shadow-hover: var(--shadow-xl);
  --before-opacity: 0;
  background-color: var(--background);
  box-shadow: var(--box-shadow);
  will-change: box-shadow;
  z-index: 2;
  transition: box-shadow .3s;
}

.btn.--primary:before {
  content: "";
  z-index: -1;
  background-image: linear-gradient(to right, var(--c-conversion), var(--c-conversion-500));
  border-radius: var(--border-radius);
  opacity: var(--before-opacity);
  will-change: opacity;
  transition: opacity .3s;
  display: block;
  position: absolute;
  inset: 0;
}

@media (hover: hover) {
  .btn.--primary:hover {
    --box-shadow: var(--box-shadow-hover);
    --before-opacity: 1;
  }
}

.btn.--outline {
  --font-color: var(--c-text);
  --border-color: var(--c-secondary);
  --background: var(--c-surface);
  border: .0625rem solid var(--border-color);
  will-change: background-color;
  background-color: var(--background);
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --background: var(--c-secondary-100);
  }
}

.btn.--ghost {
  --background: transparent;
  --font-color: var(--c-text);
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--ghost:hover {
    --background: var(--c-secondary-100);
  }
}

.btn__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.btn {
  --font-size: 1.125rem;
  --font-weight: 500;
  --font-color: var(--c-button);
  --padding-y: 1rem;
  --padding-x: 3rem;
  --min-height: 4rem;
  --min-width: 12.5rem;
  --border-radius: calc(var(--min-height) / 2);
  font-size: var(--font-size);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  min-width: var(--min-width);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  text-decoration: none;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-width: auto;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (max-width: 35.999em) {
  .btn.--icon-only-mobile {
    width: var(--min-height);
    height: var(--min-height);
    justify-content: center;
    align-items: center;
    min-width: auto;
    min-height: auto;
    padding: 0;
    display: flex;
  }

  .btn.--icon-only-mobile .btn__title {
    display: none;
  }

  .btn.--full-width-mobile {
    width: 100%;
  }

  .btn.--grow-mobile {
    flex-grow: 1;
  }
}

.c-circle-text-play {
  --square: 10rem;
  animation: 10s linear infinite paused spin;
}

@media (hover: hover) {
  .c-circle-text-play:hover {
    animation-play-state: running;
  }
}

@media (max-width: 47.999em) {
  .c-circle-text-play {
    --square: 7.5rem;
  }
}

@media (max-width: 35.999em) {
  .c-circle-text-play {
    --square: 5.5rem;
  }
}

@keyframes spin {
  0% {
    rotate: none;
  }

  100% {
    rotate: -360deg;
  }
}

.c-circle-text {
  inset: 0;
}

.c-circle-text img {
  width: 100%;
  height: 100%;
}

@media (min-width: 75em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__content .container {
  padding-left: 0;
  padding-right: 0;
}

.content-w-aside__content .s-content .entry-content h2, .content-w-aside__content .s-content .entry-content h3, .content-w-aside__content .s-content .entry-content h4, .content-w-aside__content .s-content .entry-content > p, .content-w-aside__content .s-content .entry-content > ul, .content-w-aside__content .s-content .entry-content > ol, .content-w-aside__content .s-content .entry-content > blockquote {
  padding-inline: 1.5rem;
}

@media (max-width: 35.999em) {
  .content-w-aside__content .s-content .entry-content h2, .content-w-aside__content .s-content .entry-content h3, .content-w-aside__content .s-content .entry-content h4, .content-w-aside__content .s-content .entry-content > p, .content-w-aside__content .s-content .entry-content > ul, .content-w-aside__content .s-content .entry-content > ol, .content-w-aside__content .s-content .entry-content > blockquote {
    padding-inline: 1rem;
  }
}

.content-w-aside__row {
  gap: 2rem;
}

@media (min-width: 75em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (max-width: 74.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3 / span 8;
}

.content-w-aside__aside {
  container-type: inline-size;
}

.content-w-aside__aside > div {
  margin-top: -.0625rem;
}

@media (min-width: 75em) {
  .content-w-aside__aside {
    grid-column: span 4 / -1;
  }
}

.c-cursor {
  box-shadow: var(--shadow-lg);
  z-index: 1000;
  opacity: 0;
  border-radius: 50%;
}

.c-cursor.cursor--initialized {
  display: block;
}

.c-cursor.--focused {
  opacity: 1;
}

.d-diamant {
  --square: 5.75rem;
  margin-right: -1rem;
  transition: transform .5s;
  position: relative;
}

.d-diamant circle, .d-diamant .diamant {
  transition: transform .5s;
}

.d-diamant .diamant {
  transform-origin: center;
}

.d-diamant:hover circle {
  transform: scale(1.05)translateY(.125rem);
}

.d-diamant:hover .diamant {
  transform: rotate(-15deg)translateX(.25rem)translateY(-.125rem);
}

.d-diamant svg {
  width: 115%;
  height: 115%;
}

@media (max-width: 119.999em) {
  .d-diamant {
    --square: 5.25rem;
  }
}

@media (max-width: 89.999em) {
  .d-diamant {
    --square: 5rem;
  }
}

@media (max-width: 61.999em) {
  .d-diamant {
    --square: 4rem;
  }
}

.d-helping-badge {
  will-change: rotate;
  transition: rotate .5s cubic-bezier(.68, -.55, .27, 1.55);
}

.d-helping-badge:hover {
  rotate: -15deg;
}

.d-hero-sphere {
  --square: 34rem;
  --bottom: 13rem;
  --right: 9.375rem;
  bottom: var(--bottom);
  right: var(--right);
}

@media (max-width: 119.999em) {
  .d-hero-sphere {
    --right: -1.5rem;
    --bottom: 6.25rem;
  }
}

@media (max-width: 89.999em) {
  .d-hero-sphere {
    --bottom: 2rem;
    --square: 25rem;
  }
}

@media (max-width: 61.999em) {
  .d-hero-sphere {
    --square: 18.75rem;
  }
}

@media (max-width: 47.999em) {
  .d-hero-sphere {
    --square: 12.5rem;
  }
}

.d-hero-sphere svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 35.999em) {
  .d-hero-sphere {
    display: none;
  }
}

.d-radial-gradient {
  background: radial-gradient(circle, var(--background) -40%, transparent 50%);
  width: var(--size, 100%);
  height: var(--size, 100%);
  filter: blur(2.5rem);
}

.d-radial-gradient.--primary-400 {
  --background: var(--c-primary-400);
}

.d-radial-gradient.--primary {
  --background: var(--c-primary);
}

.d-radial-gradient.--tertiary {
  --background: var(--c-tertiary);
}

.d-semi-circle {
  --width: calc(var(--height) / 2);
  --background: var(--c-secondary);
  height: var(--height, 100%);
  width: var(--width);
  background-color: var(--background);
  border-radius: 0 var(--height) var(--height) 0;
}

.d-semi-circle.--white {
  --background: var(--c-white);
}

.d-semi-circle.--black {
  --background: var(--c-text);
}

.d-semi-circle.--md {
  --height: 3.25rem;
}

.d-semi-circle.--xl {
  --height: 5rem;
}

.d-semi-circle.--xxl {
  --height: 11.5rem;
}

.d-semi-circle.--lg {
  --height: 4.5rem;
}

@media (max-width: 119.999em) {
  .d-semi-circle.--lg {
    --height: 4rem;
  }
}

@media (max-width: 35.999em) {
  .d-semi-circle.--lg {
    --height: 3.5rem;
  }
}

.c-desc-decoration--svg path {
  fill: inherit;
}

.c-desc-decoration img {
  max-width: auto;
  object-fit: cover;
  --opacity: 0;
  opacity: var(--opacity);
  width: 56rem;
  height: 5rem;
}

.c-desc-decoration img.--show {
  --opacity: 1;
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .0625rem solid var(--color);
  background-color: var(--c-surface);
  border-radius: var(--radius);
  transition: background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  background-color: var(--c-surface);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 600;
}

.f-checkbox {
  --color: var(--c-primary);
  --radius: .25rem;
  --size: 1.5rem;
  cursor: pointer;
}

.f-checkbox.--disabled {
  --color: #a9a9b2;
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--radio .f-checkbox__visual:after {
  display: block;
}

.f-error {
  --display: none;
  --left: 2rem;
  top: calc(100% + .5rem);
  left: var(--left);
  color: var(--c-danger);
  display: var(--display);
  z-index: 2;
  width: 100%;
  position: absolute;
}

.f-error.--show {
  --display: flex;
}

@media (max-width: 119.999em) {
  .f-error {
    --left: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .f-error {
    --left: .5rem;
  }
}

.f-error__icon {
  height: 1.375rem;
}

.f-error__title {
  line-height: 1.375rem;
}

.f-base {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 5.4375rem;
  padding: 1.875rem 2rem;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #a9a9b2;
}

.i-selected-file {
  cursor: pointer;
  background-color: #e7f0ff;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --font-size: 1.25rem;
  --left: 2rem;
  left: var(--left);
  top: var(--top);
  transform: translateY(var(--translate-y));
  font-size: var(--font-size);
  will-change: transform;
  transition: transform .3s, font-size .3s, top .3s;
  position: absolute;
}

@media (max-width: 119.999em) {
  .f-label {
    --font-size: 1.125rem;
    --left: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .f-label {
    --font-size: .875rem;
    --left: .5rem;
  }
}

.f-label.--required:after {
  content: "*";
  padding-left: .25rem;
}

.f-label.--active {
  --top: 0;
  --translate-y: 0;
  --font-size: .875rem;
}

@media (max-width: 35.999em) {
  .f-label.--active {
    --translate-y: -50%;
  }
}

.f-switch {
  --width: 3.75rem;
  --height: 2rem;
  --offset: .25rem;
  --background: var(--c-switch-surface);
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: var(--c-button);
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
  padding-inline: .5rem;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-switch-theme__icon {
  width: 1rem;
  height: 1rem;
}

.f-switch-theme__icon svg {
  width: 100%;
  height: 100%;
}

.f-switch-theme__icon path {
  fill: var(--c-button);
}

.f-text__input {
  color: var(--font-color);
  padding: var(--padding);
  min-height: var(--min-height);
  background-color: #0000;
}

.f-text__input::placeholder {
  color: var(--c-primary);
}

.f-text__icon.--before, .f-text__icon.--after {
  top: 50%;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--before {
  padding-left: 1rem;
  left: 0;
}

.f-text__icon.--after {
  padding-right: 1rem;
  right: 0;
}

.f-text {
  --border-color: var(--c-text);
  --font-color: var(--c-text);
  --font-size: 1.25rem;
  --padding: 1.875rem 2rem;
  --min-height: 5.4375rem;
  color: var(--font-color);
  font-size: var(--font-size);
}

.f-text.--icon-before .f-text__input {
  padding-left: 3rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3rem;
}

.f-text.--active {
  --font-color: var(--c-primary);
  --border-color: var(--c-primary);
}

.f-text.--invalid {
  --border-color: var(--c-danger);
}

@media (max-width: 119.999em) {
  .f-text {
    --font-size: 1.125rem;
    --padding: 1.375rem 1.5rem;
    --min-height: 4.5rem;
  }
}

@media (max-width: 35.999em) {
  .f-text {
    --font-size: .875rem;
    --padding: .875rem .5rem;
    --min-height: 3.125rem;
  }
}

.f-text__wrap {
  border-bottom: .0625rem solid var(--border-color);
  will-change: border-color;
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --border-color: var(--c-text);
  --font-color: var(--c-text);
  --font-size: 1.25rem;
  --padding: 1.875rem 2rem;
  --min-height: 5.4375rem;
  color: var(--font-color);
  font-size: var(--font-size);
}

.f-textarea.--invalid {
  --border-color: var(--c-danger);
}

@media (max-width: 119.999em) {
  .f-textarea {
    --font-size: 1.125rem;
    --padding: 1.375rem 1.5rem;
    --min-height: 4.4375rem;
  }
}

@media (max-width: 35.999em) {
  .f-textarea {
    --font-size: .875rem;
    --padding: .875rem .5rem;
    --min-height: 3.125rem;
  }
}

.f-textarea.--active {
  --font-color: var(--c-primary);
  --border-color: var(--c-primary);
}

.f-textarea__wrap {
  border-bottom: .0625rem solid var(--border-color);
}

.f-textarea__input {
  color: var(--font-color);
  padding: var(--padding);
  min-height: var(--min-height);
  background-color: #0000;
  height: auto;
}

@media (min-width: 62em) {
  .f-contact__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 75em) {
  .f-contact__grid {
    column-gap: 4rem;
  }
}

@media (min-width: 120em) {
  .f-contact__grid {
    column-gap: 5rem;
  }
}

@media (max-width: 61.999em) {
  .f-contact__footer {
    margin-top: 1.5rem;
  }
}

.f-contact__terms a {
  color: var(--c-text);
  font-weight: 500;
}

@media (max-width: 35.999em) {
  .f-contact__terms {
    font-size: .75rem;
  }
}

@media (min-width: 62em) {
  .f-contact__body {
    gap: 5rem;
  }
}

@media (min-width: 120em) {
  .f-contact__body {
    column-gap: 6rem;
  }
}

@media (min-width: 62em) {
  .f-contact__header {
    margin-bottom: .5rem;
  }
}

@media (min-width: 120em) {
  .f-contact__header {
    margin-bottom: 1.5rem;
  }
}

.f-contact__tags {
  max-width: 56.875rem;
}

.f-newsletter {
  --p: 5rem;
  --icon-size: 7rem;
  --radius: 3.5rem;
  --heading-fz: 2rem;
  --gap: 2.5rem;
  --header-gap: 2rem;
  --button-display: block;
  --button-icon-display: none;
  border-radius: var(--radius);
  gap: var(--gap);
  padding: var(--p);
}

@container (width <= 896px) {
  .f-newsletter {
    --p: 3.5rem;
    --gap: 2rem;
    --header-gap: 1.5rem;
    --heading-fz: 1.5rem;
    --button-display: none;
    --button-icon-display: block;
  }

  .f-newsletter .f-newsletter__arrow.--xl {
    display: block;
  }

  .f-newsletter .f-newsletter__arrow.--xl ~ .f-newsletter__arrow {
    display: none;
  }
}

@container (width <= 500px) {
  .f-newsletter {
    --p: 1.5rem;
    --gap: 2rem;
    --icon-size: 4rem;
    --header-gap: 1rem;
    --heading-fz: 1.25rem;
    --radius: 2.5rem;
  }

  .f-newsletter .f-newsletter__arrow.--l {
    display: block;
  }

  .f-newsletter .f-newsletter__arrow.--l ~ .f-newsletter__arrow {
    display: none;
  }
}

@container (width <= 350px) {
  .f-newsletter {
    --gap: 1.5rem;
  }

  .f-newsletter .f-newsletter__arrow.--m {
    display: block;
  }

  .f-newsletter .f-newsletter__arrow.--m ~ .f-newsletter__arrow {
    display: none;
  }
}

.f-newsletter__icon {
  width: var(--icon-size);
  height: var(--icon-size);
  padding: .5rem;
}

.f-newsletter__button-icon {
  display: var(--button-icon-display);
}

.f-newsletter__button {
  display: var(--button-display);
}

.f-newsletter__heading {
  font-size: var(--heading-fz);
  line-height: 1.25;
}

.f-newsletter__header {
  max-width: 100%;
}

.f-newsletter__header .base-header__description {
  font-size: 1rem;
  line-height: 1.25rem;
}

.f-newsletter__arrow {
  display: none;
}

.g-about-images {
  grid-template-columns: repeat(3, 1fr);
}

@media (min-width: 120em) {
  .g-about-images {
    grid-template-columns: 35.5rem 39rem 35.5rem;
  }
}

@media (max-width: 119.999em) {
  .g-about-images {
    gap: 1rem;
  }
}

@media (max-width: 47.999em) {
  .g-about-images {
    grid-template-columns: repeat(3, 13rem);
  }
}

.g-alerts {
  width: min(100%, 40rem);
}

.g-base {
  flex-wrap: wrap;
  display: flex;
}

.g-base.--gap-xs {
  gap: .25rem;
}

.g-base.--gap-sm {
  gap: .5rem;
}

.g-base.--gap-md {
  gap: 1rem;
}

.g-base.--gap-lg {
  gap: 1.5rem;
}

.g-base.--gap-xl {
  gap: 2rem;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 6rem;
  margin-top: var(--spacing);
}

@media (max-width: 119.999em) {
  .g-buttons {
    --spacing: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .g-buttons {
    --spacing: 3rem;
  }
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

.g-colors {
  border: .0625rem solid var(--c-secondary);
  border-radius: 3.5rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2rem 15.375rem 2rem 2rem;
}

@media (max-width: 74.999em) {
  .g-colors {
    padding: 1rem 7.6875rem 1rem 1rem;
  }
}

.g-commendations .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  height: auto;
  padding-block: .5625rem;
  padding-inline: .25rem;
  transform: translate3d(0, 0, 0);
}

.g-commendations__icon {
  bottom: 0;
  right: 0;
  transform: translateY(calc(50% + 1rem))translateX(1rem);
}

.g-commendations__fraction.swiper-pagination-fraction {
  width: auto;
}

@media (max-width: 35.999em) {
  .g-commendations__nav {
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .g-commendations__fraction {
    font-size: 1rem;
  }
}

.g-commendations__button {
  will-change: opacity;
  transition: opacity .3s;
}

.g-commendations__button.swiper-button-disabled {
  opacity: .2;
  cursor: not-allowed;
}

.g-partners__decoration {
  height: var(--decoration-size);
}

.g-partners__radial {
  --size: 17rem;
  bottom: 0;
  right: 0;
  transform: translateX(30%)translateY(30%);
}

@media (max-width: 89.999em) {
  .g-partners__radial {
    --size: 15rem;
  }
}

.g-partners__row {
  --semi-circle-size: 13rem;
  --decoration-size: 13rem;
}

@media (max-width: 119.999em) {
  .g-partners__row {
    --decoration-size: 11rem;
    --semi-circle-size: 11rem;
  }
}

@media (min-width: 75em) {
  .g-partners__row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 90em) {
  .g-partners__row:nth-child(odd) {
    grid-template-columns: 1fr 1fr var(--decoration-size);
  }

  .g-partners__row:nth-child(2n) {
    grid-template-columns: 6.5rem 1fr 1fr;
  }
}

.g-partners__row:not(:first-child) .g-partners__decoration {
  border: .0625rem solid var(--c-secondary);
  border-radius: 50%;
}

.g-partners__row:not(:first-child) .g-partners__decoration img, .g-partners__row:not(:first-child) .g-partners__radial {
  display: none;
}

.g-partners__semi-circle {
  --height: var(--semi-circle-size);
}

.g-persons__cell {
  margin-top: -.0625rem;
  margin-right: -.0625rem;
}

.g-pills__cell {
  --skew: 0;
  will-change: rotate, margin-bottom;
  transition: rotate .5s cubic-bezier(.68, -.55, .27, 3), margin-bottom .5s;
}

.g-pills__cell.--skew {
  --skew: -6deg;
  rotate: var(--skew);
}

.g-pills__cell.--skew:nth-child(2n) {
  --skew: 6deg;
}

@media (max-width: 35.999em) {
  .g-pills__cell.--skew {
    margin-bottom: 1.5rem;
  }
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 89.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .g-posts {
    max-width: 38rem;
    margin-inline: auto;
  }
}

.g-references {
  grid-template-columns: repeat(12, 1fr);
}

@media (max-width: 119.999em) {
  .g-references {
    gap: 1rem;
  }
}

.g-references .g-references__cell {
  --span: 3;
  grid-column: auto / span var(--span);
}

@media (max-width: 74.999em) {
  .g-references .g-references__cell {
    --span: 6;
  }
}

.g-references .g-references__cell:nth-child(6n), .g-references .g-references__cell:nth-child(7n), .g-references .g-references__cell:first-child {
  --span: 6;
}

@media (max-width: 74.999em) {
  .g-references .g-references__cell:nth-child(6n), .g-references .g-references__cell:nth-child(7n), .g-references .g-references__cell:first-child {
    --span: 12;
  }
}

.g-services {
  display: grid;
}

@media (min-width: 48em) {
  .g-services {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media (min-width: 90em) {
  .g-services {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 89.999em) {
  .g-services {
    max-width: 57rem;
    margin-inline: auto;
  }
}

@media (max-width: 47.999em) {
  .g-services {
    max-width: 28.5rem;
  }
}

.g-services__radial {
  z-index: -1;
  bottom: 2rem;
  right: 2rem;
  transform: translateX(50%)translateY(45%);
}

.g-services__radial.--lg {
  --size: 33.5rem;
  --opacity: .5;
  bottom: 5.5rem;
}

@media (max-width: 119.999em) {
  .g-services__radial.--lg {
    --size: 30.5rem;
  }
}

@media (max-width: 47.999em) {
  .g-services__radial.--lg {
    --size: 19rem;
  }
}

.g-services__radial.--sm {
  --size: 19.5rem;
}

@media (max-width: 119.999em) {
  .g-services__radial.--sm {
    --size: 18rem;
  }
}

@media (max-width: 47.999em) {
  .g-services__radial.--sm {
    --size: 11rem;
  }
}

@media (max-width: 35.999em) {
  .g-tags.gap-2 {
    gap: .5rem;
  }
}

.g-technologies {
  --radius: 3.5rem;
  border-radius: var(--radius);
}

@media (max-width: 119.999em) {
  .g-technologies {
    --radius: 2.5rem;
  }
}

.g-testimonials.--infinite {
  margin-top: 4rem;
}

@media (max-width: 119.999em) {
  .g-testimonials.--infinite {
    margin-top: 3rem;
  }
}

@media (max-width: 35.999em) {
  .g-testimonials.--infinite {
    margin-top: 2.5rem;
  }
}

@keyframes scroll {
  to {
    transform: translate(-50%);
  }
}

.g-testimonials.--infinite .g-testimonials__inner {
  gap: 7.5rem;
  width: max-content;
  animation: 90s linear infinite forwards scroll;
}

@media (max-width: 119.999em) {
  .g-testimonials.--infinite .g-testimonials__inner {
    gap: 6rem;
  }
}

@media (max-width: 74.999em) {
  .g-testimonials.--infinite .g-testimonials__inner {
    gap: 3.5rem;
  }
}

@media (max-width: 35.999em) {
  .g-testimonials.--infinite .g-testimonials__inner {
    gap: 3rem;
  }
}

.g-testimonials.--infinite.--view-hidden .g-testimonials__inner {
  animation-play-state: paused;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  will-change: width;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 62em) {
  .g-usps {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media (min-width: 90em) {
  .g-usps {
    grid-template-columns: repeat(4, 1fr);
  }

  .g-usps__cell.--slide {
    will-change: transform;
    transition: transform .3s;
    transform: translateY(2.5rem);
  }
}

.c-heading-pill {
  --height: 5rem;
  --radius: calc(var(--height) / 2);
  --rotate: 0deg;
  height: var(--height);
  border-radius: var(--radius);
  aspect-ratio: 3;
}

@media (max-width: 119.999em) {
  .c-heading-pill {
    --height: 4.5rem;
  }
}

@media (max-width: 35.999em) {
  .c-heading-pill {
    --height: 3.5rem;
  }
}

.c-heading-pill:hover {
  --rotate: 30deg;
}

.c-heading-pill__inner {
  transform: translateX(-50%) translateY(-50%) rotate(var(--rotate));
  will-change: transform;
  transition: transform 1s;
}

.c-hr__visual:after, .c-hr__visual:before {
  content: "";
  background-color: var(--c-secondary);
  width: calc(50% - 1.5rem);
  height: .0625rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.c-hr__visual:before {
  left: 0;
}

.c-hr__visual:after {
  right: 0;
}

.icon {
  --size: 1.25rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
}

.icon.--primary {
  --color: var(--c-primary);
}

.icon.--conversion {
  --color: var(--c-conversion);
}

.icon.--conversion-600 {
  --color: var(--c-conversion-600);
}

.icon.--white {
  --color: var(--c-white);
}

.icon.--black {
  --color: var(--c-text);
}

.icon.--danger {
  --color: var(--c-danger);
}

.icon.--xxxxl {
  --size: 4rem;
}

.icon.--xxxl {
  --size: 2.875rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .75rem;
}

@media (max-width: 119.999em) {
  .img--ball {
    --square: 12.5rem;
  }
}

@media (max-width: 61.999em) {
  .img--ball {
    --square: 9rem;
  }
}

@media (max-width: 47.999em) {
  .img--ball {
    --square: 5.5rem;
  }
}

.img-diamant {
  --size: 5rem;
  --offset: 1.5rem;
  width: var(--size);
}

@media (max-width: 119.999em) {
  .img-diamant {
    --size: 4.5rem;
  }
}

@media (max-width: 74.999em) {
  .img-diamant {
    --size: 3.75rem;
  }
}

@media (max-width: 61.999em) {
  .img-diamant {
    --size: 3.5rem;
  }
}

.img__noise {
  background-image: url("noise.a28c3500.svg");
}

@media (max-width: 119.999em) {
  .img-star-l, .img--time {
    --square: 1rem;
  }
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--ratio-3-1 {
  aspect-ratio: 3;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  background: linear-gradient(45deg, var(--c-secondary) 0%, var(--c-surface) 30%, var(--c-secondary) 62%);
  opacity: 1;
  z-index: -1;
  background-size: 200%;
  width: 100%;
  height: 100%;
  animation: 3s ease-in-out infinite alternate placeholder;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.img__noise {
  z-index: 1;
  mix-blend-mode: soft-light;
  opacity: .3;
  image-rendering: crisp-edges;
  background-repeat: repeat;
  inset: 0;
}

@keyframes placeholder {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 100% 0;
  }
}

.i-about-image__img {
  border-radius: var(--radius);
}

.i-about-image {
  --radius: 2.5rem;
  border-radius: var(--radius);
  transform-origin: bottom;
  will-change: transform;
  transition: transform .3s;
}

@media (max-width: 119.999em) {
  .i-about-image {
    --radius: 2rem;
  }
}

@media (max-width: 47.999em) {
  .i-about-image {
    --radius: 1.5rem;
  }
}

.i-about-image.--default {
  margin-top: auto;
}

.i-about-image.--default:after {
  content: "";
  mix-blend-mode: color;
  background-color: var(--c-secondary);
  border-radius: var(--radius);
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate3d(0, 0, 0);
}

.i-about-image.--default .i-about-image__img {
  opacity: .8;
  filter: grayscale();
  border-radius: var(--radius);
}

.i-about-image.--highlight {
  margin-bottom: 5.375rem;
}

@media (min-width: 120em) {
  .i-about-image.--highlight {
    margin-bottom: 3rem;
  }
}

@media (max-width: 74.999em) {
  .i-about-image.--highlight {
    margin-bottom: 3.5rem;
  }
}

@media (max-width: 47.999em) {
  .i-about-image.--highlight {
    margin-bottom: 2.5rem;
  }
}

.i-about-image.--left {
  --skew: -9deg;
}

.i-about-image.--right {
  --skew: 9deg;
}

.i-about-image.--skew {
  transform: rotate(var(--skew));
}

.i-alert {
  --background: var(--c-surface);
  --color: var(--c-text);
  --opacity: 0;
  --translate: -2rem;
  --fz: 1.5rem;
  --p: 2rem;
  --radius: 2.5rem;
  --icon-size: 4rem;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  transform: translateY(var(--translate));
  pointer-events: none;
  will-change: opacity, transform;
  font-size: var(--fz);
  padding: var(--p);
  border-radius: var(--radius);
  box-shadow: var(--shadow-sm);
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --color: var(--c-success);
}

.i-alert.--danger {
  --color: var(--c-danger);
}

@media (max-width: 119.999em) {
  .i-alert {
    --fz: 1.125rem;
    --radius: 2rem;
    --p: 1.5rem;
    --icon-size: 3.5rem;
  }
}

@media (max-width: 47.999em) {
  .i-alert {
    --fz: 1rem;
    --radius: 1.5rem;
    --p: 1.5rem;
    --icon-size: 2rem;
  }
}

@media (max-width: 35.999em) {
  .i-alert {
    --fz: .875rem;
    --p: 1rem;
  }
}

.i-alert__icon {
  --square: var(--icon-size);
  border-radius: 4rem;
}

.i-alert__icon svg {
  width: 100%;
  height: 100%;
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author__title {
  font-size: 1.125rem;
}

.i-author {
  --image-size: 4rem;
}

@media (max-width: 35.999em) {
  .i-author {
    --image-size: 3rem;
  }

  .i-author .i-author__body {
    row-gap: 0;
  }
}

.i-author.--compact {
  --image-size: 3.5rem;
}

@media (max-width: 119.999em) {
  .i-author.--compact {
    --image-size: 3rem;
  }
}

.i-author.--compact .i-author__title {
  font-size: 1rem;
}

.i-author.--compact .i-author__body {
  row-gap: 0;
}

.i-author.--compact .i-author__rank {
  font-size: .875rem;
}

.i-author__image {
  --radius: 50%;
  border-radius: var(--radius);
  width: var(--image-size);
  height: var(--image-size);
  max-width: var(--image-size);
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-color {
  margin-right: -13.375rem;
}

@media (max-width: 74.999em) {
  .i-color {
    margin-right: -6.6875rem;
  }
}

@media (max-width: 35.999em) {
  .i-color {
    margin-right: -5.875rem;
  }
}

.i-color__item {
  border-radius: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 17.5rem;
  height: 28rem;
  padding: 1.25rem;
  font-weight: 400;
  display: flex;
}

@media (max-width: 74.999em) {
  .i-color__item {
    width: 10.5rem;
    height: 14rem;
  }
}

@media (max-width: 35.999em) {
  .i-color__item {
    border-radius: 1rem;
    width: 8.5rem;
  }
}

.i-color__item .i-color__title {
  font-size: 2rem;
}

@media (max-width: 74.999em) {
  .i-color__item .i-color__title {
    font-size: 1rem;
  }
}

.i-color__item .i-color__bottom-text {
  align-self: end;
  font-size: 6rem;
}

@media (max-width: 74.999em) {
  .i-color__item .i-color__bottom-text {
    font-size: 3rem;
  }
}

.i-color__item:not(:hover) .i-color__title, .i-color__item:not(:hover) .i-color__bottom-text {
  opacity: 0;
}

.i-color__item:hover .i-color__title, .i-color__item:hover .i-color__bottom-text, .i-color__item.--last-not-hovered .i-color__title, .i-color__item.--last-not-hovered .i-color__bottom-text {
  opacity: 1;
}

.i-commendation__hr {
  height: .0625rem;
}

.i-commendation {
  border-radius: 2.5rem;
  transition: transform .3s;
}

@media (min-width: 62em) {
  .i-commendation.--skew-negative {
    transform: rotate(-2deg);
  }

  .i-commendation.--skew-positive {
    transform: rotate(2deg);
  }
}

@media (max-width: 35.999em) {
  .i-commendation {
    border-radius: 1.5rem;
  }
}

.i-commendation__description {
  --mh: 8.75rem;
  max-height: var(--mh);
  padding-right: 1rem;
  line-height: 1.5rem;
}

@media (max-width: 119.999em) {
  .i-commendation__description {
    --mh: 10.75rem;
    font-size: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-commendation__description {
    --mh: 13.75rem;
    font-size: .875rem;
  }
}

@media (max-width: 119.999em) {
  .i-date {
    font-size: .875rem;
  }
}

@media (max-width: 35.999em) {
  .i-date {
    gap: .25rem;
  }
}

.i-date.--size-l {
  --fz: 1.125rem;
  font-size: var(--fz);
}

@media (max-width: 119.999em) {
  .i-date.--size-l {
    --fz: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-date.--size-l {
    --fz: .875rem;
  }
}

.i-date[style] .icon path {
  fill: currentColor;
}

.i-document {
  border-style: solid;
  border-width: .0625rem;
  border-color: var(--c-secondary);
  border-radius: .25rem;
}

.i-empty {
  margin-top: var(--spacing);
  max-width: 28rem;
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
  transform: translateZ(0);
}

@media (max-width: 35.999em) {
  .i-faq__title {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.i-faq__body-inner {
  line-height: 1.25rem;
}

@media (min-width: 36em) {
  .i-faq__body-inner {
    padding-left: 5rem;
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}

.i-faq {
  --p: 2.5rem;
  --toggler-size: 1.5rem;
  --minus-display: none;
  --plus-display: block;
  padding: var(--p);
  border-bottom: .0625rem solid var(--c-secondary);
  border-radius: var(--border-radius);
}

@media (max-width: 35.999em) {
  .i-faq {
    --p: 1.5rem;
    --toggler-size: 1rem;
  }
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open {
  --minus-display: block;
  --plus-display: none;
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-faq.--hidden {
  display: none;
}

.i-faq__icon-border, .i-faq__toggler {
  --size: 5.5rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

@media (max-width: 35.999em) {
  .i-faq__icon-border, .i-faq__toggler {
    --size: 3rem;
  }
}

.i-faq__icon {
  --size: 3.5rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

@media (max-width: 35.999em) {
  .i-faq__icon {
    --size: 2rem;
  }
}

.i-faq__toggler-icon {
  width: var(--toggler-size);
  height: var(--toggler-size);
}

.i-faq__toggler-icon.--minus {
  display: var(--minus-display);
}

.i-faq__toggler-icon.--plus {
  display: var(--plus-display);
}

@media (max-width: 35.999em) {
  .i-faq__header {
    align-items: flex-start;
    gap: .5rem;
  }
}

.i-heading-anchor__counter {
  --min-size: 2.5rem;
  --radius: calc(var(--min-size) / 2);
  min-width: var(--min-size);
  height: var(--min-size);
  border-radius: var(--radius);
}

.i-media.--video {
  cursor: pointer;
}

.i-menu {
  --padding-left: 1rem;
  --padding-right: 3rem;
  --background: transparent;
  --padding-block: 1.46875rem;
  --fz: 1.25rem;
}

.i-menu__link {
  padding-block: var(--padding-block);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  will-change: background-color;
  color: var(--c-text);
  font-size: var(--fz);
  min-height: 2.5rem;
  text-decoration: none;
  transition: background-color .3s;
}

.i-menu__link:hover {
  color: var(--c-text);
}

@media (min-width: 75em) {
  .i-menu {
    --padding-left: 1rem;
    --padding-right: 1rem;
    --fz: 1rem;
  }
}

@media (min-width: 75em) and (max-width: 89.999em) {
  .i-menu {
    --fz: .875rem;
  }
}

.i-notification {
  background-color: var(--c-conversion);
  color: var(--c-white);
  min-height: 2.5rem;
}

@media (max-width: 35.999em) {
  .i-notification {
    text-align: center;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 52rem);
}

@media (max-width: 35.999em) {
  .i-notification__container {
    flex-direction: column;
    gap: .5rem;
  }

  .i-notification__icon {
    margin-inline: auto;
  }

  .i-notification__close {
    order: -1;
  }
}

.i-number {
  border-radius: 3.5rem;
}

.i-number__image picture, .i-number__image img {
  max-width: 200vw;
  height: 5rem;
}

.i-number__title {
  --size: 5.5rem;
  font-size: var(--size);
  line-height: var(--size);
}

@media (max-width: 119.999em) {
  .i-number__title {
    --size: 4rem;
  }
}

@media (max-width: 61.999em) {
  .i-number__title {
    --size: 3.5rem;
  }
}

.i-number__postfix {
  --font-size: 2.5rem;
  --line-height: 3.5rem;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

@media (max-width: 61.999em) {
  .i-number__postfix {
    --font-size: 2rem;
    --line-height: 2.5rem;
  }
}

.i-number__perex {
  --font-size: 1.5rem;
  --line-height: 1.875rem;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.i-partner {
  border-radius: 6.5rem;
  min-height: 13rem;
}

@media (max-width: 119.999em) {
  .i-partner {
    min-height: 11rem;
  }
}

@media (max-width: 35.999em) {
  .i-partner {
    text-align: center;
    border-radius: 5.5rem 5.5rem 2.5rem 2.5rem;
    flex-direction: column;
    align-items: center;
  }
}

.i-partner__image {
  --width: 13rem;
  --height: 10rem;
  --radius: calc(var(--height) / 2);
  max-width: var(--width);
  flex: 0 0 var(--width);
  height: var(--height);
  border-radius: var(--radius);
}

.i-partner__image .img {
  justify-content: center;
  align-items: center;
  max-height: 5.5rem;
  display: flex;
}

@media (max-width: 119.999em) {
  .i-partner__image {
    --width: 10.375rem;
    --height: 8rem;
  }
}

@media (max-width: 35.999em) {
  .i-partner__image {
    --width: 100%;
    flex: auto;
    width: 100%;
  }
}

.i-person {
  border: .0625rem solid var(--c-secondary);
  border-radius: 3.5rem;
  padding: 3rem;
}

.i-person__image-col {
  --size: 13rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

@media (max-width: 61.999em) {
  .i-person__image-col {
    --size: 13rem;
    padding: 2rem;
  }
}

@media (max-width: 35.999em) {
  .i-person__image-col {
    --size: 14rem;
    padding: 1.5rem;
  }
}

.i-pill {
  --inline: 2.5rem;
  --radius: 1.5rem;
  --height: 5rem;
  padding-inline: var(--inline);
  border-radius: var(--radius);
  min-height: var(--height);
  color: var(--c-button);
  padding-block: .5rem;
}

@media (max-width: 47.999em) {
  .i-pill {
    --inline: 2rem;
    --height: 4.5rem;
  }
}

.i-pill__icon {
  --size: 1.5rem;
  height: var(--size);
  max-width: var(--size);
  flex: 0 0 var(--size);
}

body.--dark-theme .i-pill {
  background-color: var(--c-text);
}

.i-post {
  --radius: 3.5rem;
  --padding: 1.5rem;
  --gap: 2.5rem;
  gap: var(--gap);
  border-radius: var(--radius);
  padding: var(--padding);
}

@media (max-width: 119.999em) {
  .i-post {
    --padding: 1rem;
    --radius: 3rem;
    --gap: 2rem;
  }
}

@media (max-width: 35.999em) {
  .i-post {
    --radius: 2.5rem;
    --gap: 1.5rem;
  }
}

.i-post__image-link {
  --radius: 2.5rem;
  border-radius: var(--radius);
}

@media (max-width: 35.999em) {
  .i-post__image-link {
    --radius: 2rem;
  }
}

.i-post__heading-link {
  text-wrap: balance;
}

.i-post__heading-link:hover {
  color: var(--c-text);
}

.i-post__content-col {
  --padding: 1rem;
  padding: 0 var(--padding) var(--padding);
  gap: var(--gap);
}

@media (max-width: 35.999em) {
  .i-post__content-col {
    --padding: .5rem;
  }

  .i-post__footer {
    flex-direction: column;
    align-items: start;
  }

  .i-post__tags {
    display: none;
  }

  .i-post__tags.--open {
    display: block;
  }

  .i-post__tags-expand.--hide {
    display: none;
  }
}

@media (min-width: 62em) {
  .i-post__tags-close {
    display: none;
  }
}

.i-quote__hr {
  width: 100%;
  height: .0625rem;
  margin-top: 1rem;
}

.i-quote {
  max-width: 75.3125rem;
}

@media (max-width: 35.999em) {
  .i-quote {
    gap: 1.5rem;
    padding-inline: 1rem;
  }
}

.i-quote__description {
  --font-size: 2.5rem;
  --line-height: 4rem;
  line-height: var(--line-height);
  font-size: var(--font-size);
}

@media (max-width: 119.999em) {
  .i-quote__description {
    --font-size: 2rem;
  }
}

@media (max-width: 74.999em) {
  .i-quote__description {
    --font-size: 1.5rem;
    --line-height: 3rem;
  }
}

@media (max-width: 35.999em) {
  .i-quote__description {
    --font-size: 1.125rem;
    --line-height: 1.75rem;
  }

  .i-quote__icon {
    width: 2.5rem;
  }
}

.i-read-time {
  --fz: 1.125rem;
  font-size: var(--fz);
}

@media (max-width: 119.999em) {
  .i-read-time {
    --fz: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-read-time {
    --fz: .875rem;
    gap: .25rem;
  }
}

.i-reference {
  --height: 28rem;
  --radius: 2.5rem;
  --opacity: 0;
  --tags-display: none;
  --max-height: 50.5rem;
}

@media (max-width: 119.999em) {
  .i-reference {
    --height: 21rem;
    --max-height: 37.5rem;
  }
}

@media (max-width: 61.999em) {
  .i-reference {
    --height: 9.5rem;
    --radius: 1.5rem;
    --max-height: 17rem;
  }
}

@media (min-width: 62em) {
  .i-reference {
    --tags-display: block;
  }
}

.i-reference.--open {
  --opacity: 1;
}

.i-reference.--open-tags {
  --tags-display: block;
  --expand-display: none;
}

.i-reference:hover {
  color: var(--c-text);
}

.i-reference .i-reference__image {
  height: var(--height);
  border-radius: var(--radius);
  will-change: height;
}

.i-reference .i-reference__image img {
  border-radius: var(--radius);
}

.i-reference__tags {
  display: var(--tags-display);
}

.i-reference__image-wrapper {
  height: var(--max-height);
  border-radius: var(--radius);
}

.i-reference__tags, .i-reference__header, .i-reference__actions {
  will-change: opacity;
  opacity: var(--opacity);
  transition: opacity .3s;
}

@media (max-width: 47.999em) {
  .i-reference__header {
    flex-wrap: wrap;
  }
}

@media (min-width: 62em) {
  .i-reference__tags-close {
    display: none;
  }
}

@media (max-width: 61.999em) {
  .i-reference__actions {
    display: var(--expand-display);
  }
}

.i-service__icon-stack {
  --offset: 1.5rem;
  bottom: var(--offset);
  right: var(--offset);
}

@media (max-width: 119.999em) {
  .i-service__icon-stack {
    --offset: 1rem;
  }
}

.i-service__abstract {
  height: var(--abstract-size);
  width: var(--abstract-size);
  transform: translateY(var(--abstract-transform-y));
  transition: transform .5s;
}

.i-service__heading {
  --font-size: 2rem;
  font-size: var(--font-size);
}

@media (max-width: 119.999em) {
  .i-service__heading {
    --font-size: 1.5rem;
  }
}

@media (max-width: 89.999em) {
  .i-service__heading {
    --font-size: 1.25rem;
  }
}

.i-service__link {
  color: var(--c-button);
  text-decoration: none;
}

.i-service__link:hover, .i-service__link:focus {
  color: var(--c-button);
}

@media (hover: hover) {
  .i-service__link:hover {
    --icon-rotate: -15deg;
    --abstract-transform-y: .5rem;
  }
}

.i-service {
  --radius: 3.5rem;
  --padding: 1.5rem 1.5rem 2.5rem;
  --icon-rotate: 0deg;
  --icon-size: 4.5rem;
  --abstract-size: 7.625rem;
  --abstract-transform-y: 0;
  --gap: 2.5rem;
  --translate-y: 0;
  border-radius: var(--radius);
  padding: var(--padding);
  will-change: transform;
  background-color: var(--c-surface);
  gap: var(--gap);
  transform: translateY(var(--translate-y));
  transition: transform .5s;
}

@media (max-width: 119.999em) {
  .i-service {
    --radius: 3rem;
    --padding: 1rem 1rem 2rem;
    --gap: 2rem;
  }
}

@media (max-width: 47.999em) {
  .i-service {
    --padding: 1rem 1rem 1.5rem;
    --gap: 2rem;
  }
}

@media (max-width: 35.999em) {
  .i-service {
    --icon-size: 3.5rem;
  }
}

@media (min-width: 90em) {
  .i-service:has(.i-service__link:hover) {
    --translate-y: 1rem;
  }
}

.i-service.--order-2 .i-service__abstract {
  left: 25%;
}

.i-service.--order-4 .i-service__abstract {
  left: 12%;
}

.i-service__body {
  --padding-inline: 1rem;
  padding-inline: var(--padding-inline);
}

@media (max-width: 47.999em) {
  .i-service__body {
    --padding-inline: .5rem;
  }
}

.i-service__icon {
  --border-radius: calc(var(--icon-size) / 2);
  height: var(--icon-size);
  width: var(--icon-size);
  border-radius: var(--border-radius);
  rotate: var(--icon-rotate);
  transition: rotate .5s;
}

@media (max-width: 35.999em) {
  .i-service__icon {
    --size: 3.5rem;
  }
}

.i-service__icon img {
  width: 3rem;
  height: 3rem;
}

.i-service__semi-circle {
  --height: var(--icon-size);
}

body.--dark-theme .i-service, body.--dark-theme .i-service__icon {
  background-color: var(--c-text);
}

body.--dark-theme .i-service__semi-circle {
  --background: var(--c-button);
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .125rem;
  --font-size: 1rem;
  --min-height: 2rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  font-size: var(--font-size);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .125rem;
  --min-height: 1.5rem;
  --font-size: .75rem;
}

.i-tag.--md {
  --padding-x: 1rem;
  --padding-y: .125rem;
  --min-height: 2.5rem;
  --font-size: .75rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 3rem;
}

@media (max-width: 35.999em) {
  .i-tag.--lg {
    --padding-x: 1rem;
    --padding-y: .125rem;
    --min-height: 2.5rem;
    --font-size: .75rem;
  }
}

.i-tag {
  --font-color: var(--c-primary);
  --font-size: .875rem;
  --background-color: var(--c-surface);
  --min-height: 2rem;
  --border-radius: calc(var(--min-height) / 2);
  background-color: var(--background-color);
  color: var(--font-color);
  border: .0625rem solid var(--background-color);
  min-height: var(--min-height);
  border-radius: var(--border-radius);
  font-size: var(--font-size);
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

.i-tag.--input {
  will-change: border-color, background-color;
  transition-property: border-color, background-color;
}

.i-tag.--secondary {
  --background-color: var(--c-secondary);
}

.i-tag.--outline {
  border-color: var(--c-secondary);
}

label.i-tag, a.i-tag, .i-tag.--hoverable {
  will-change: border-color;
  cursor: pointer;
  text-decoration: none;
  transition: border-color .3s;
}

label.i-tag:hover, a.i-tag:hover, .i-tag.--hoverable:hover {
  border-color: var(--c-primary);
}

a.i-tag.--blank {
  will-change: text-decoration-color;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: #0000;
}

.i-tag__input {
  pointer-events: none;
  opacity: 0;
  bottom: 0;
  left: 0;
}

.i-tag:has(.i-tag__input:checked) {
  --font-color: var(--c-white);
  --background-color: var(--c-primary);
}

.i-technology {
  --height: 10rem;
  --radius: 3.5rem;
  --p: 3rem;
  height: var(--height);
  border-radius: var(--radius);
  padding: var(--p);
}

.i-technology.--empty {
  --size: 6rem;
  --radius: 6rem;
  width: var(--size);
  height: var(--size);
}

.i-technology.--empty.--index-1 {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 119.999em) {
  .i-technology.--empty {
    --size: 5rem;
  }
}

@media (max-width: 35.999em) {
  .i-technology.--empty {
    --size: 3rem;
  }
}

.i-technology img {
  max-width: 5.4375rem;
  max-height: 4rem;
}

@media (max-width: 119.999em) {
  .i-technology {
    --p: 2rem;
  }
}

@media (max-width: 35.999em) {
  .i-technology {
    --height: 5.875rem;
    --radius: 1.5rem;
  }

  .i-technology img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.i-testimonial img {
  object-fit: contain;
  max-height: 7.5rem;
}

@media (max-width: 119.999em) {
  .i-testimonial img {
    max-height: 6rem;
  }
}

@media (max-width: 74.999em) {
  .i-testimonial img {
    max-height: 6rem;
  }
}

@media (max-width: 35.999em) {
  .i-testimonial img {
    max-height: 4rem;
  }
}

.i-textation__order {
  width: 8.75rem;
}

.i-textation__order:before, .i-textation__order:after {
  content: attr(data-symbol);
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
}

.i-textation__order:after {
  transform: rotate(180deg);
}

.i-usp {
  border-radius: 3.5rem;
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .i-usp {
    padding: 2rem;
    padding-block: 2.5rem;
  }
}

@media (max-width: 35.999em) {
  .i-usp {
    border-radius: 2.5rem;
    padding: 2rem;
  }
}

.i-usp path {
  fill: var(--c-text);
}

@media (max-width: 35.999em) {
  .i-usp__icon {
    --square: 2rem;
  }
}

.i-usp__heading {
  --fz: 1.5rem;
  font-size: var(--fz);
}

@media (max-width: 35.999em) {
  .i-usp__heading {
    --fz: 1.125rem;
  }
}

@media (max-width: 119.999em) {
  .i-usp__description {
    font-size: 1rem;
  }
}

@media (max-width: 35.999em) {
  .i-usp__description {
    font-size: .875rem;
  }

  .i-usp__body {
    gap: .5rem;
  }
}

.c-language-select__drowpdown {
  background-color: var(--c-surface);
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  z-index: 5;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-top: none;
  width: calc(100% + .125rem);
  top: 100%;
  left: -.0625rem;
}

.c-language-select__active {
  min-height: var(--size);
  min-width: var(--size);
}

.c-language-select__dropdown-link {
  will-change: background-color;
  min-height: var(--size);
  color: var(--c-text);
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:last-child {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.c-language-select__dropdown-link:hover {
  background-color: var(--c-secondary);
  color: var(--c-text);
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  --size: 3.25rem;
  --border-radius: calc(var(--size) / 2);
  border-radius: var(--border-radius);
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  border-bottom-color: #0000;
}

.c-language-select.--horizontal {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
  border: none;
  flex-direction: row;
  display: flex;
}

.c-language-select.--horizontal .c-language-select__drowpdown {
  background-color: #0000;
  border: none;
  flex-direction: row;
  width: auto;
  position: static;
}

.c-language-select.--horizontal .c-language-select__dropdown-link {
  min-width: var(--size);
}

.link {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .link.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .link.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  color: var(--c-text);
  text-decoration-color: #0000;
}

.link.--black:hover, .link.--black:focus {
  color: var(--c-text);
  -webkit-text-decoration-color: var(--c-text);
  text-decoration-color: var(--c-text);
}

.link.--primary {
  color: var(--c-primary);
  text-decoration-color: #0000;
}

.link.--primary:hover, .link.--primary:focus {
  color: var(--c-primary);
  -webkit-text-decoration-color: var(--c-primary);
  text-decoration-color: var(--c-primary);
}

.link.--conversion {
  color: var(--c-conversion);
  text-decoration-color: #0000;
}

.link.--conversion:hover, .link.--conversion:focus {
  color: var(--c-conversion);
  -webkit-text-decoration-color: var(--c-conversion);
  text-decoration-color: var(--c-conversion);
}

.link.--conversion-600 {
  color: var(--c-conversion-600);
  text-decoration-color: #0000;
}

.link.--conversion-600:hover, .link.--conversion-600:focus {
  color: var(--c-conversion-600);
  -webkit-text-decoration-color: var(--c-conversion-600);
  text-decoration-color: var(--c-conversion-600);
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  will-change: ext-decoration-color;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.link.--no-hover {
  text-decoration: none;
}

.main-footer-badge__radial {
  --size: 12.5rem;
}

@media (min-width: 90em) {
  .main-footer-badge__radial {
    top: calc(50% - .5rem);
    left: calc(50% - 3rem);
    transform: translateX(-50%)translateY(-50%);
  }
}

@media (max-width: 89.999em) {
  .main-footer-badge__radial {
    bottom: 1.5rem;
    left: calc(50% - .5rem);
    transform: translateX(-50%)translateY(50%);
  }
}

@media (max-width: 35.999em) {
  .main-footer-badge__radial {
    --size: 8.5rem;
  }
}

@media (max-width: 89.999em) {
  .main-footer-badge {
    flex-direction: row;
    align-items: self-start;
  }
}

@media (max-width: 35.999em) {
  .main-footer-badge svg {
    width: 9rem;
    height: 9rem;
  }
}

@media (max-width: 89.999em) {
  .main-footer-badge__semicircle {
    width: 5.75rem;
    height: 11.5rem;
  }
}

@media (max-width: 35.999em) {
  .main-footer-badge__semicircle {
    width: 4rem;
    height: 9rem;
  }

  .main-footer-column {
    gap: 2rem;
  }
}

.main-footer-column__list.--cols {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6rem;
  display: grid;
}

@media (max-width: 119.999em) {
  .main-footer-column__list.--cols {
    column-gap: 5rem;
  }
}

@media (max-width: 89.999em) {
  .main-footer-column__list.--cols {
    column-gap: 3.5rem;
  }
}

@media (max-width: 35.999em) {
  .main-footer-column__list.--cols {
    display: flex;
  }
}

.main-footer__columns {
  --p-block: 5rem;
  padding-block: var(--p-block);
  border-top: .0625rem solid var(--c-secondary);
  border-bottom: .0625rem solid var(--c-secondary);
  gap: 4.5rem 2rem;
}

@media (max-width: 119.999em) {
  .main-footer__columns {
    --padding: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .main-footer__columns {
    --padding: 3rem;
  }
}

@media (min-width: 48em) and (max-width: 89.999em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (max-width: 47.999em) {
  .main-footer__columns {
    flex-direction: column;
  }
}

.main-footer {
  --margin-top: 5.25rem;
  --p-inline: 4rem;
  margin-top: var(--margin-top);
}

@media (max-width: 119.999em) {
  .main-footer {
    --p-inline: 3.5rem;
  }
}

@media (max-width: 47.999em) {
  .main-footer {
    --p-inline: 2rem;
  }
}

@media (max-width: 119.999em) {
  .main-footer {
    --margin-top: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .main-footer {
    --margin-top: 2.5rem;
  }

  .main-footer__socials {
    gap: 1.5rem;
  }
}

.main-footer__bottom {
  --p-block: 3rem;
  padding-block: var(--p-block);
}

.main-footer__bottom-inner, .main-footer__columns {
  padding-inline: var(--p-inline);
}

.m-header {
  --shadow-color: transparent;
  --asymmetry: 2rem;
  --min-height: 7.5rem;
  --p-block: 1.625rem;
  --logo-max-w: 9.5rem;
  --semi-circle-height: 3.25rem;
  --button-height: 3.25rem;
  --bg-opacity: 1;
  z-index: 5;
  width: 100%;
  transition: transform .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.m-header:before {
  content: "";
  background-color: var(--c-surface);
  opacity: var(--bg-opacity);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

@media (min-width: 75em) {
  .m-header.headroom--unpinned {
    transform: translateY(-100%);
  }
}

.m-header.headroom--not-top {
  --p-block: 1rem;
  --logo-max-w: 7.5rem;
  --semi-circle-height: 3rem;
  --button-height: 3rem;
  --bg-opacity: .9;
}

.m-header.headroom--not-top:before {
  -webkit-backdrop-filter: blur(.625rem);
  backdrop-filter: blur(.625rem);
}

@media (min-width: 75em) and (max-width: 89.999em) {
  .m-header.headroom--not-top {
    --logo-max-w: 5rem;
  }
}

@media (max-width: 35.999em) {
  .m-header.headroom--not-top {
    --logo-max-w: 5rem;
  }
}

@media (min-width: 75em) and (max-width: 89.999em) {
  .m-header {
    --logo-max-w: 6.25rem;
  }
}

@media (max-width: 35.999em) {
  .m-header {
    --p-block: .875rem;
    --logo-max-w: 6.875rem;
  }
}

.m-header__body {
  padding-block: var(--p-block);
  will-change: padding;
  transition: padding .3s;
}

@media (max-width: 35.999em) {
  .m-header__body {
    --asymmetry: 1rem;
  }
}

.m-header__body:after {
  content: "";
  width: calc(100% + var(--asymmetry));
  background-color: var(--c-secondary);
  height: .0625rem;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.m-header__semi-circle {
  --height: var(--semi-circle-height);
  will-change: height, width;
  transition: height .3s, width .3s;
}

.m-header-offset {
  --offset: 7.5rem;
  padding-top: var(--offset);
}

@media (max-width: 35.999em) {
  .m-header-offset {
    --offset: 5rem;
  }
}

.m-header-logo {
  max-width: var(--logo-max-w);
  will-change: max-width;
  transition: max-width .3s;
}

.m-header .m-header__btn-form.btn.--xs {
  --min-height: var(--button-height);
  will-change: min-height;
  transition: min-height .3s;
}

.m-nav {
  --transition-timing: 0;
}

@media (min-width: 75em) {
  .m-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 74.999em) {
  .m-nav {
    background-color: var(--c-secondary);
    transition: transform var(--transition-timing) ease;
    will-change: transform;
    z-index: 100;
    flex-direction: column;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
    box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
  }

  .m-nav.--open {
    transform: translateX(0);
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

@media (min-width: 75em) {
  .m-nav__list {
    flex-direction: row;
    align-items: start;
  }
}

@media (max-width: 74.999em) {
  .m-nav__list {
    margin-top: 2rem;
  }
}

.m-nav__semi-circle {
  --height: 3.5rem;
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  pointer-events: auto;
  background-clip: padding-box;
  background-color: var(--content-bg);
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  border-radius: var(--radius);
  outline: 0;
  margin-inline: auto;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__body {
  padding: 2rem var(--p-inline);
  flex: auto;
  position: relative;
}

.c-modal__header {
  padding: var(--p-block) var(--p-inline) 0;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-modal__button-container {
  order: -1;
  justify-content: flex-end;
  display: flex;
}

.c-modal__close-cross {
  transform: translate(-1rem, 1rem);
}

.c-modal__footer {
  padding: 0 var(--p-inline) var(--p-block);
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 45rem);
  --content-bg: var(--c-white);
  --p-block: 1.5rem;
  --p-inline: 1.5rem;
  --radius: 2.5rem;
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  background-color: var(--c-modal-dialog);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  will-change: opacity;
  outline: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-lg {
  --width: min(100% - 2rem, 61rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 114rem);
  --p-block: 5rem;
  --p-inline: 6.5rem;
}

@media (max-width: 119.999em) {
  .c-modal.--size-xl {
    --p-block: 4.5rem;
    --p-inline: 5rem;
    --radius: 2rem;
  }
}

@media (max-width: 47.999em) {
  .c-modal.--size-xl {
    --p-block: 2rem;
    --p-inline: 2rem;
    --radius: 1.5rem;
  }
}

.c-modal.--bg-secondary {
  --content-bg: var(--c-secondary);
}

.m-contact-success__icon {
  border-radius: 50%;
  width: 5.5rem;
  height: 5.5rem;
  margin-top: -.5rem;
}

.m-contact-success__icon svg {
  width: 100%;
  height: 100%;
}

.m-contact-success__header {
  --spacing: 3rem;
  gap: .5rem;
  width: min(100%, 48rem);
}

.m-contact-success__header h2 {
  --size: 1.5rem;
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 5;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  will-change: opacity;
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

@media (max-width: 119.999em) {
  .c-page-pagination__button.btn {
    width: 2rem;
    height: 2rem;
  }
}

.c-scroll-indicator {
  --dot-size: .5rem;
  --dot-border-radius: calc(var(--dot-size) / 2);
  bottom: -.03125rem;
}

@keyframes dot-slide {
  from {
    opacity: 0;
    transform: translateX(-50%)translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateX(-50%)translateY(3.25rem);
  }
}

.c-scroll-indicator:before {
  content: "";
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--dot-border-radius);
  background-color: var(--c-primary);
  opacity: 0;
  animation: 1.5s infinite forwards dot-slide;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)translateY(1rem);
}

@media (max-width: 47.999em) {
  .c-scroll-indicator {
    display: none;
  }
}

.c-scroll-indicator.--view-hidden:before {
  animation-play-state: paused;
}

.s-author-header__button {
  margin-bottom: var(--spacing);
}

.s-author-header__image-col {
  --size: 22rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  padding: 2.5rem;
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-author-header__image-col {
    --size: 18.75rem;
  }
}

@media (max-width: 61.999em) {
  .s-author-header__image-col {
    --size: 15.625rem;
    padding: 2rem;
  }
}

@media (max-width: 35.999em) {
  .s-author-header__image-col {
    --size: 13rem;
    padding: 1.5rem;
  }
}

.s-author-header__image {
  border-radius: 50%;
}

.s-author-header__description {
  --fz: 1.25rem;
  --lh: 1.5rem;
  font-size: var(--fz);
  line-height: var(--lh);
}

@media (max-width: 35.999em) {
  .s-author-header__description {
    --fz: 1rem;
  }
}

.s-author-header__rank {
  --fz: 1.125rem;
  font-size: var(--fz);
}

@media (max-width: 35.999em) {
  .s-author-header__rank {
    --fz: 1rem;
  }
}

.s-author-header__body-col {
  --gap: 4.5rem;
  gap: var(--gap);
}

@media (max-width: 61.999em) {
  .s-author-header__body-col {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 35.999em) {
  .s-author-header__body-col {
    --gap: 1.5rem;
  }

  .s-author-header__content {
    gap: 1.5rem;
  }
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-categories-list__grid {
  --gap: 5rem;
  gap: var(--gap);
}

.s-categories-list__line {
  background-color: var(--c-secondary);
  flex-grow: 1;
  height: .0625rem;
}

@media (max-width: 35.999em) {
  .s-categories-list__line {
    display: none;
  }
}

.s-categories-list__header {
  row-gap: 2rem;
}

@media (max-width: 47.999em) {
  .s-categories-list__header {
    text-align: center;
    flex-direction: column;
  }

  .s-categories-list__button {
    width: 100%;
    margin-left: 0;
  }
}

.s-colors-typo__grid {
  --mw: 56rem;
  max-width: var(--mw);
  row-gap: 3.5rem;
  display: grid;
}

@media (min-width: 62em) {
  .s-colors-typo__grid {
    --mw: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-commendations__wrapper {
  --spacing: 6rem;
  margin-top: var(--spacing);
}

@media (max-width: 119.999em) {
  .s-commendations__wrapper {
    --spacing: 4.5rem;
  }
}

@media (max-width: 47.999em) {
  .s-commendations__wrapper {
    --spacing: 3rem;
  }
}

.s-contact-form__mask {
  --size: 15.5rem;
  width: var(--size);
  height: var(--size);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  top: -.0625rem;
  right: -.0625rem;
}

.s-contact-form__mask svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 119.999em) {
  .s-contact-form__mask {
    --size: 13rem;
  }
}

@media (max-width: 74.999em) {
  .s-contact-form__mask {
    --size: 4.8125rem;
  }
}

.s-contact-form__radial {
  --size: 27rem;
  top: 0;
  right: 0;
  transform: translateX(50%)translateY(-50%);
}

@media (max-width: 119.999em) {
  .s-contact-form__radial {
    --size: 23rem;
  }
}

@media (max-width: 74.999em) {
  .s-contact-form__radial {
    --size: 5.625rem;
  }
}

.s-contact-form__sun-dial {
  width: 4.5rem;
  height: 4.5rem;
  transform: translateY(-1.625rem);
}

.s-contact-form__sun-dial svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 74.999em) {
  .s-contact-form__sun-dial {
    display: none;
  }
}

@media (max-width: 119.999em) {
  .s-contact-form__abstract {
    width: 7rem;
  }
}

@media (max-width: 74.999em) {
  .s-contact-form__abstract {
    width: 2.5rem;
  }
}

.s-cta__decoration {
  border-radius: 4.5rem;
  height: 9rem;
}

.s-cta__decoration .img {
  border-radius: 4.5rem;
  overflow: hidden;
}

@media (max-width: 47.999em) {
  .s-cta__decoration {
    margin-bottom: 1rem;
  }
}

.s-cta__body {
  border-radius: 3.5rem;
}

@media (max-width: 47.999em) {
  .s-cta__body {
    text-align: center;
    border-radius: 2rem;
    padding: 2rem;
  }
}

.s-cta__radial {
  --size: 14.5rem;
  top: 0;
  left: 0;
  transform: translateX(-30%)translateY(-30%);
}

.s-cta__abstract {
  bottom: 1.5rem;
  left: 10%;
  transform: translateY(50%);
}

@media (max-width: 47.999em) {
  .s-cta__abstract {
    width: 5.5rem;
  }

  .s-cta__button {
    flex-grow: 1;
  }
}

@media (min-width: 75em) {
  .s-double-content__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-double-content {
  --radius: 3.5rem;
}

.s-double-content .s-double-content__title {
  margin-top: 0;
}

.s-double-content .img {
  border-radius: var(--radius);
  margin-block: 0;
}

@media (max-width: 35.999em) {
  .s-double-content {
    --radius: 2.5rem;
  }
}

.s-double-content__body {
  padding-inline: 1.5rem;
}

@media (max-width: 35.999em) {
  .s-double-content__body {
    padding-inline: 1rem;
  }
}

.s-double__grid {
  --mw: 56rem;
  max-width: var(--mw);
  row-gap: 3.5rem;
  display: grid;
}

.s-double__grid .f-newsletter {
  padding: 3.5rem;
}

.s-double__grid .f-newsletter .f-newsletter__button {
  display: none;
}

.s-double__grid .f-newsletter .f-newsletter__button-icon-only {
  display: block;
}

@media (min-width: 90em) {
  .s-double__grid {
    --mw: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) {
  .s-404__description-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    display: grid;
  }
}

.s-404__description {
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
}

@media (min-width: 36em) {
  .s-404__description {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}

.s-404__title {
  --fz: 20rem;
  font-size: var(--fz);
}

@media (max-width: 119.999em) {
  .s-404__title {
    --fz: 15rem;
  }
}

@media (max-width: 89.999em) {
  .s-404__title {
    --fz: 11.25rem;
  }
}

@media (max-width: 74.999em) {
  .s-404__title {
    --fz: 8.75rem;
  }
}

@media (max-width: 61.999em) {
  .s-404__title {
    --fz: 6.875rem;
  }
}

@media (max-width: 47.999em) {
  .s-404__title {
    --fz: 12.5rem;
    flex-direction: column;
    line-height: 12.5rem;
    display: flex;
  }
}

@media (max-width: 35.999em) {
  .s-404__title {
    --fz: 10rem;
    line-height: 10rem;
  }

  .s-gallery__content {
    flex-direction: column;
  }
}

.s-gallery__column.--reverse {
  flex-direction: column-reverse;
}

@media (max-width: 35.999em) {
  .s-gallery__column.--reverse {
    flex-direction: column;
  }
}

.s-gallery__item .img {
  border-radius: 3.5rem;
}

@media (max-width: 47.999em) {
  .s-gallery__item .img {
    border-radius: 2.625rem;
  }
}

@media (max-width: 35.999em) {
  .s-gallery__item .img {
    border-radius: 1.75rem;
  }
}

.s-hero-media__circle-decoration {
  --right: 0;
  right: var(--right);
  top: 0;
}

@media (max-width: 119.999em) {
  .s-hero-media__circle-decoration {
    width: 15rem;
  }
}

@media (max-width: 89.999em) {
  .s-hero-media__circle-decoration {
    width: 12rem;
  }
}

@media (max-width: 74.999em) {
  .s-hero-media__circle-decoration {
    width: 9.75rem;
  }
}

@media (max-width: 61.999em) {
  .s-hero-media__circle-decoration {
    width: 7.75rem;
  }
}

@media (max-width: 47.999em) {
  .s-hero-media__circle-decoration {
    width: 9rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero-media__circle-decoration {
    width: 4rem;
  }
}

.s-hero-media__reference-decoration {
  --right: 0;
  right: var(--right);
  top: 0;
}

.s-hero-media__reference-decoration .icon {
  --size: 21rem;
}

@media (max-width: 119.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 16.875rem;
  }
}

@media (max-width: 89.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 13.4375rem;
  }
}

@media (max-width: 74.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 11.25rem;
  }
}

@media (max-width: 61.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 8.125rem;
  }
}

@media (max-width: 47.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 5.9375rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero-media__reference-decoration .icon {
    --size: 4rem;
  }
}

.s-hero-media__reference-decoration[style] .icon path {
  fill: currentColor;
}

.s-hero-reference__bg-decoration {
  z-index: -1;
  border-radius: 3.5rem;
  width: calc(100vw - 1rem);
  height: calc(100% - 12rem);
  display: block;
  position: absolute;
  top: 2.5rem;
  left: .5rem;
}

.s-hero-reference__bg-decoration img {
  width: auto;
}

@media (min-width: 48em) {
  .s-hero-reference__bg-decoration {
    width: calc(100vw - 3rem);
    height: calc(100% - 16rem);
    top: 4.5rem;
    left: 1.5rem;
  }
}

@media (min-width: 120em) {
  .s-hero-reference__bg-decoration {
    height: calc(100% - 19rem);
    top: 6rem;
  }
}

.s-hero-reference.--bottom-0 .s-hero-reference__bg-decoration {
  height: calc(100% - 9.5rem);
}

@media (min-width: 48em) {
  .s-hero-reference.--bottom-0 .s-hero-reference__bg-decoration {
    height: calc(100% - 11.5rem);
    top: 4.5rem;
  }
}

@media (min-width: 120em) {
  .s-hero-reference.--bottom-0 .s-hero-reference__bg-decoration {
    height: calc(100% - 13rem);
    top: 6rem;
  }
}

.s-hero-reference.--top-0 .s-hero-reference__bg-decoration {
  height: calc(100% - 9.5rem);
  top: 0;
}

@media (min-width: 48em) {
  .s-hero-reference.--top-0 .s-hero-reference__bg-decoration {
    height: calc(100% - 11.5rem);
  }
}

@media (min-width: 120em) {
  .s-hero-reference.--top-0 .s-hero-reference__bg-decoration {
    height: calc(100% - 13rem);
  }
}

.s-hero__badge {
  --offset: 3rem;
  --svg-size: 11.5rem;
  bottom: var(--offset);
  right: var(--offset);
}

.s-hero__badge svg {
  width: var(--svg-size);
  height: var(--svg-size);
}

@media (max-width: 119.999em) {
  .s-hero__badge {
    --offset: 2.5rem;
  }
}

@media (max-width: 89.999em) {
  .s-hero__badge {
    --svg-size: 10.5rem;
    --offset: 2rem;
  }
}

@media (max-width: 74.999em) {
  .s-hero__badge {
    --offset: 2rem;
    --svg-size: 8rem;
    gap: 1rem;
  }
}

@media (max-width: 61.999em) {
  .s-hero__badge {
    --offset: 1rem;
    --svg-size: 6rem;
  }
}

@media (max-width: 47.999em) {
  .s-hero__badge {
    --svg-size: 3.5rem;
    gap: .5rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero__badge {
    display: none;
  }
}

.s-hero__semi-circle {
  --height: var(--svg-size);
}

.s-hero__sun-dial-decoration {
  bottom: 14.375rem;
  left: -1.5rem;
}

@media (max-width: 119.999em) {
  .s-hero__sun-dial-decoration {
    bottom: 8.125rem;
  }
}

@media (max-width: 89.999em) {
  .s-hero__sun-dial-decoration {
    bottom: 5rem;
  }
}

@media (max-width: 74.999em) {
  .s-hero__sun-dial-decoration {
    bottom: 2.5rem;
  }
}

@media (max-width: 61.999em) {
  .s-hero__sun-dial-decoration {
    width: 7.625rem;
    bottom: 3.125rem;
  }
}

@media (max-width: 47.999em) {
  .s-hero__sun-dial-decoration {
    bottom: 1.25rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero__sun-dial-decoration {
    top: calc(50% - 4.5rem);
    bottom: auto;
    left: calc(50% + 5rem);
    transform: translateX(-50%)translateY(-50%);
  }
}

.s-hero__radial.--lg {
  --size: 33.5rem;
  top: 0;
  right: 0;
  transform: translateX(calc(50% - 3rem))translateY(calc(6.25rem - 50%));
}

@media (max-width: 119.999em) {
  .s-hero__radial.--lg {
    --size: 29rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero__radial.--lg {
    --size: 15rem;
    top: 3rem;
    left: 3rem;
    right: auto;
    transform: translateX(-50%)translateY(-50%);
  }
}

.s-hero__radial.--sm {
  --size: 23rem;
  bottom: 3rem;
  left: 3rem;
  transform: translateX(-50%)translateY(50%);
}

@media (max-width: 119.999em) {
  .s-hero__radial.--sm {
    --size: 20.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-hero__radial.--sm {
    --size: 15rem;
    left: auto;
    right: 3rem;
    transform: translateX(50%)translateY(50%);
  }
}

.s-hero__mask {
  --ratio: 38 / 15;
}

.s-hero__mask img {
  aspect-ratio: var(--ratio);
}

@media (max-width: 119.999em) {
  .s-hero__mask {
    --ratio: 170 / 81;
  }
}

@media (max-width: 35.999em) {
  .s-hero__mask {
    --ratio: 19 / 16;
  }
}

.s-hero__heading {
  --fz: 8.5rem;
  --lh: 1;
  font-size: var(--fz);
  line-height: var(--lh);
  max-width: 81.25rem;
}

@media (max-width: 119.999em) {
  .s-hero__heading {
    --fz: 7rem;
  }
}

@media (max-width: 74.999em) {
  .s-hero__heading {
    --fz: 5rem;
  }
}

@media (max-width: 61.999em) {
  .s-hero__heading {
    --fz: 3.75rem;
    --lh: 1.2;
  }
}

@media (max-width: 47.999em) {
  .s-hero__heading {
    flex-direction: column;
    gap: .25rem;
    display: flex;
  }
}

@media (max-width: 35.999em) {
  .s-hero__heading {
    --fz: 3rem;
    --lh: 1;
  }
}

.s-hero__image {
  margin-top: 2.5rem;
}

@media (min-width: 36em) {
  .s-hero__image {
    margin-top: 5rem;
  }
}

@media (min-width: 75em) and (max-width: 89.999em) {
  .s-hero__image {
    margin-top: 8.75rem;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .s-hero__image {
    margin-top: 7.5rem;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .s-hero__image {
    margin-top: 6.875rem;
  }
}

@media (min-width: 36em) and (max-width: 47.999em) {
  .s-hero__image {
    margin-top: 15.625rem;
  }
}

.s-hero__wrap {
  display: grid;
}

@media (min-width: 36em) {
  .s-hero__wrap .s-hero__header, .s-hero__wrap .s-hero__image {
    grid-row-start: 1;
    grid-column-start: 1;
  }
}

@media (min-width: 75em) {
  .s-intro__description-grid {
    grid-template-columns: 7fr 5fr;
    gap: 2rem;
    display: grid;
  }
}

@media (min-width: 120em) {
  .s-intro__description-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-intro__description {
  max-width: 100%;
  font-size: 1.25rem;
}

.s-intro__description span {
  color: var(--c-primary);
  font-weight: 500;
}

@media (max-width: 119.999em) {
  .s-intro__description {
    font-size: 1.125rem;
  }
}

@media (max-width: 35.999em) {
  .s-intro__description {
    font-size: 1rem;
  }
}

@media (max-width: 47.999em) {
  .s-intro__title {
    flex-direction: column;
    display: flex;
  }
}

.s-intro__tags-categories .g-tags {
  align-items: center;
}

.s-intro__tags-categories .i-tag {
  max-height: 3rem;
}

@media (max-width: 119.999em) {
  .s-intro__tags-categories {
    margin-top: 2.625rem;
  }
}

@media (max-width: 35.999em) {
  .s-intro__tags-categories {
    margin-top: 1.5rem;
  }
}

@media (max-width: 61.999em) {
  .s-intro__metas {
    gap: 1rem;
  }

  .s-intro__metas .c-vr:first-child {
    display: none;
  }
}

.s-intro__button {
  margin-left: auto;
}

.s-intro__button[style] .btn {
  color: inherit;
}

.s-intro__button[style] .btn path {
  fill: currentColor;
}

.s-contact-info__body {
  gap: 4.5rem;
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-contact-info__body {
    gap: 3rem;
  }
}

@media (max-width: 61.999em) {
  .s-contact-info__body {
    gap: 3rem;
  }
}

@media (max-width: 47.999em) {
  .s-contact-info__body {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}

.s-contact-info__content-col {
  width: 100%;
}

@media (max-width: 47.999em) {
  .s-contact-info__content-col {
    text-align: left;
    row-gap: 2rem;
  }
}

.s-contact-info {
  --padding: 5rem;
  --radius: 3.5rem;
  padding: var(--padding);
  border: .0625rem solid var(--c-secondary);
  border-radius: var(--radius);
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-contact-info {
    --padding: 5rem;
  }
}

@media (max-width: 61.999em) {
  .s-contact-info {
    --padding: 2rem;
    --radius: 2.5rem;
  }
}

.s-contact-info__contact-title {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

@media (max-width: 89.999em) {
  .s-contact-info__contact-title {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}

.s-contact-info__contacts {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 2.5rem;
  display: grid;
}

@media (min-width: 36em) {
  .s-contact-info__contacts {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 48em) {
  .s-contact-info__contacts {
    row-gap: 4rem;
  }
}

@media (min-width: 90em) {
  .s-contact-info__contacts {
    gap: 6.5rem 3.5rem;
  }
}

.s-general-info__body {
  gap: 4.5rem;
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-general-info__body {
    gap: 3rem;
  }
}

@media (max-width: 61.999em) {
  .s-general-info__body {
    gap: 3rem;
  }
}

@media (max-width: 47.999em) {
  .s-general-info__body {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .s-general-info__content-col {
    text-align: left;
    row-gap: 2rem;
  }
}

.s-general-info__header {
  justify-content: space-between;
  row-gap: 2.5rem;
  height: 100%;
}

@media (max-width: 47.999em) {
  .s-general-info__header {
    gap: 2.5rem;
  }
}

.s-general-info__title {
  font-size: 1.5rem;
  line-height: 1.875rem;
}

@media (max-width: 89.999em) {
  .s-general-info__title {
    font-size: 1.25rem;
    line-height: 1.5625rem;
  }
}

.s-general-info {
  --padding: 5rem;
  --radius: 3.5rem;
  padding: var(--padding);
  border: .0625rem solid var(--c-secondary);
  border-radius: var(--radius);
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-general-info {
    --padding: 5rem;
  }
}

@media (max-width: 61.999em) {
  .s-general-info {
    --padding: 2rem;
    --radius: 2.5rem;
  }
}

.s-location .s-double__grid {
  row-gap: 1rem;
}

.m-circle-text {
  animation: 18s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(-360deg);
  }
}

.s-map__circle-decoration {
  right: 0;
}

@media (max-width: 119.999em) {
  .s-map__circle-decoration {
    width: 5.5rem;
  }
}

@media (max-width: 89.999em) {
  .s-map__circle-decoration {
    width: 4.5rem;
  }
}

@media (max-width: 74.999em) {
  .s-map__circle-decoration {
    width: 3.75rem;
  }
}

@media (max-width: 61.999em) {
  .s-map__circle-decoration {
    width: 2.75rem;
  }
}

@media (max-width: 47.999em) {
  .s-map__circle-decoration {
    width: 5rem;
  }
}

@media (max-width: 35.999em) {
  .s-map__circle-decoration {
    width: 3.75rem;
  }
}

.s-media__grid {
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 62em) {
  .s-media__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-media__item .img {
  border-radius: 3.5rem;
}

.s-mention__img {
  --size: 6rem;
  height: var(--size);
  width: var(--size);
}

.s-mention__perex {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (max-width: 119.999em) {
  .s-mention__perex {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }

  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }

  .s-post-detail__share {
    flex-direction: column;
  }
}

@media (max-width: 61.999em) {
  .s-post-header__metas {
    gap: 1rem;
  }

  .s-post-header__metas .c-vr:first-child {
    display: none;
  }
}

.s-post-header__header {
  max-width: 100%;
}

@media (max-width: 119.999em) {
  .s-post-header__tags-categories {
    margin-top: 2.625rem;
  }
}

@media (max-width: 35.999em) {
  .s-post-header__tags-categories {
    margin-top: 1.5rem;
  }
}

.s-posts-list__filters-row {
  --mb: 4rem;
  margin-bottom: var(--mb);
}

@media (max-width: 35.999em) {
  .s-posts-list__filters-row {
    --mb: 2.5rem;
  }
}

@media (max-width: 74.999em) {
  .s-posts-list__search-col {
    order: -1;
  }
}

@media (max-width: 35.999em) {
  .s-posts-list__tags {
    gap: .5rem;
  }
}

.s-search-result-intro__query:before {
  content: "“";
}

.s-search-result-intro__query:after {
  content: "”";
}

.s-service-intro__icon-stack {
  --size: 11.5rem;
  --offset: 3rem;
  right: var(--offset);
  bottom: var(--offset);
  z-index: 2;
}

@media (max-width: 119.999em) {
  .s-service-intro__icon-stack {
    --offset: 2.5rem;
  }
}

@media (max-width: 89.999em) {
  .s-service-intro__icon-stack {
    --size: 7.5rem;
    --offset: 2rem;
  }
}

@media (max-width: 47.999em) {
  .s-service-intro__icon-stack {
    --size: 5rem;
    --offset: 1rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__icon-stack {
    --size: 3.5rem;
    gap: .5rem;
  }
}

.s-service-intro__icon {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
}

@media (max-width: 89.999em) {
  .s-service-intro__icon {
    --padding: 1.5rem;
    padding: var(--padding);
  }
}

@media (max-width: 47.999em) {
  .s-service-intro__icon {
    --padding: 1rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__icon {
    --padding: .5rem;
  }
}

.s-service-intro__semi-circle {
  --height: var(--size);
}

.s-service-intro__abstract {
  --size: 12.5rem;
  left: var(--abstract-left);
  width: var(--size);
  height: var(--size);
  top: 50%;
  transform: translateY(-20%);
}

@media (max-width: 89.999em) {
  .s-service-intro__abstract {
    --size: 9.5rem;
  }
}

@media (min-width: 36em) and (max-width: 47.999em) {
  .s-service-intro__abstract {
    --size: 5.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__abstract {
    --size: 7.625rem;
    top: 0;
    transform: translateY(0);
  }
}

.s-service-intro__radial.--lg {
  --size: 33.5rem;
  top: 0;
  right: 0;
  transform: translateX(calc(50% - 3rem))translateY(calc(6.25rem - 50%));
}

@media (max-width: 119.999em) {
  .s-service-intro__radial.--lg {
    --size: 29rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__radial.--lg {
    --size: 15rem;
    transform: translateX(calc(50% - 3rem))translateY(calc(2.5rem - 50%));
  }
}

.s-service-intro__radial.--sm {
  --size: 23rem;
  bottom: 3rem;
  left: 3rem;
  transform: translateX(-50%)translateY(50%);
}

@media (min-width: 48em) {
  .s-service-intro__radial.--sm {
    left: 25%;
  }
}

@media (min-width: 90em) {
  .s-service-intro__radial.--sm {
    left: 35%;
  }
}

@media (max-width: 119.999em) {
  .s-service-intro__radial.--sm {
    --size: 15rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__radial.--sm {
    --size: 11.5rem;
  }
}

.s-service-intro__description {
  --mw: 56rem;
  max-width: var(--mw);
}

@media (min-width: 36em) {
  .s-service-intro__description {
    min-height: 3.5rem;
  }
}

@media (max-width: 119.999em) {
  .s-service-intro__description {
    --mw: 49.1875rem;
  }
}

@media (max-width: 89.999em) {
  .s-service-intro__description {
    --mw: 70%;
  }
}

@media (max-width: 74.999em) {
  .s-service-intro__description {
    --mw: 60%;
  }
}

@media (max-width: 47.999em) {
  .s-service-intro__description {
    --mw: 100%;
    font-size: 1rem;
  }
}

.s-service-intro__title-semi-circle {
  --height: 5rem;
}

@media (max-width: 119.999em) {
  .s-service-intro__title-semi-circle {
    --height: 4.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__title-semi-circle {
    --height: 3.5rem;
  }

  .s-service-intro__title {
    flex-direction: column;
    gap: .5rem;
    display: flex;
  }
}

.s-service-intro__mask {
  --ratio: 38 / 15;
}

.s-service-intro__mask img {
  aspect-ratio: var(--ratio);
}

@media (max-width: 119.999em) {
  .s-service-intro__mask {
    --ratio: 170 / 81;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__mask {
    --ratio: 38 / 23;
  }
}

.s-service-intro__header {
  --offset: -13.75rem;
  margin-bottom: var(--offset);
}

@media (max-width: 119.999em) {
  .s-service-intro__header {
    --offset: -12.5rem;
  }
}

@media (max-width: 89.999em) {
  .s-service-intro__header {
    --offset: -9.375rem;
  }
}

@media (max-width: 74.999em) {
  .s-service-intro__header {
    --offset: -7.5rem;
  }
}

@media (max-width: 61.999em) {
  .s-service-intro__header {
    --offset: -6.25rem;
  }
}

@media (max-width: 47.999em) {
  .s-service-intro__header {
    --offset: -3.125rem;
  }
}

@media (max-width: 35.999em) {
  .s-service-intro__header {
    --offset: 0;
  }
}

.s-service-intro.--digi {
  --abstract-left: 0;
}

.s-service-intro.--dev {
  --abstract-left: 10%;
}

.s-service-intro.--design {
  --abstract-left: 0;
}

.s-service-intro.--market {
  --abstract-left: 5%;
}

@media (max-width: 35.999em) {
  .s-service-intro__wrap {
    gap: 2.5rem;
  }
}

.s-share__body {
  --px: 3rem;
  --py: 2.5rem;
  padding-inline: var(--px);
  padding-block: var(--py);
  border-radius: 3.5rem;
  min-height: 7rem;
}

@media (max-width: 35.999em) {
  .s-share__body {
    --px: 1.5rem;
    --py: 1.5rem;
  }
}

.s-share__abstract {
  --square: 7rem;
}

.s-share__radial {
  --size: 13.5rem;
  bottom: 0;
  right: 0;
  transform: translateX(40%)translateY(35%);
}

.s-share__eye {
  top: 0;
  right: 0;
  transform: translateX(25%)translateY(-35%);
}

@media (max-width: 35.999em) {
  .s-share__list {
    justify-content: center;
  }
}

.s-specialist__image-col {
  --size: 22rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-specialist__image-col {
    --size: 18.75rem;
  }
}

@media (max-width: 61.999em) {
  .s-specialist__image-col {
    --size: 15.625rem;
    padding: 2rem;
  }
}

@media (max-width: 35.999em) {
  .s-specialist__image-col {
    --size: 13rem;
    padding: 1.5rem;
  }
}

.s-specialist__image {
  border-radius: 50%;
}

.s-specialist__body {
  gap: 4.5rem;
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-specialist__body {
    gap: 3rem;
  }
}

@media (max-width: 61.999em) {
  .s-specialist__body {
    gap: 3rem;
  }
}

@media (max-width: 47.999em) {
  .s-specialist__body {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .s-specialist__content-col {
    text-align: center;
    align-items: center;
    row-gap: 2rem;
  }
}

.s-specialist {
  --padding: 4.4375rem;
  --radius: 3.5rem;
  padding: var(--padding);
  border-radius: var(--radius);
}

@media (min-width: 90em) and (max-width: 119.999em) {
  .s-specialist {
    --padding: 2rem;
  }
}

@media (max-width: 61.999em) {
  .s-specialist {
    --padding: 2rem;
    --radius: 2.5rem;
  }
}

.s-specialist__rank {
  --fz: 1.125rem;
  font-size: var(--fz);
}

@media (max-width: 119.999em) {
  .s-specialist__rank {
    --fz: 1rem;
  }
}

.s-technologies__title {
  --font-size: 3rem;
  --line-height: 3.5rem;
  font-size: var(--font-size);
  line-height: var(--line-height);
}

@media (max-width: 119.999em) {
  .s-technologies__title {
    --font-size: 2rem;
    --line-height: 2.5rem;
  }
}

.s-technologies__img {
  --size: 6rem;
  height: var(--size);
  width: var(--size);
}

.s-text-media__grid {
  gap: 2rem 7rem;
}

@media (max-width: 119.999em) {
  .s-text-media__grid {
    column-gap: 4rem;
  }
}

@media (max-width: 89.999em) {
  .s-text-media__grid {
    column-gap: 2rem;
  }
}

@media (min-width: 75em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__header-in {
  gap: .5rem;
  margin-bottom: 3.5rem;
}

@media (max-width: 74.999em) {
  .s-text-media__header-in {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-text-media__header-in {
    margin-bottom: 1rem;
  }
}

.s-text-media__col.--content {
  padding-left: 0;
  padding-right: 1.5rem;
}

@media (max-width: 35.999em) {
  .s-text-media__col.--content {
    padding-right: 0;
    padding-inline: 1rem;
  }
}

@media (max-width: 74.999em) {
  .s-text-media__col.--media {
    max-width: 56rem;
    margin-inline: auto;
  }
}

.s-text-media {
  --radius: 3.5rem;
}

.s-text-media .img {
  border-radius: var(--radius);
}

.s-text-media .g-buttons {
  --spacing: 3.5rem;
}

.s-text-media.--reverse .s-text-media__col.--content {
  order: -1;
  padding-left: 1.5rem;
  padding-right: 0;
}

@media (max-width: 74.999em) {
  .s-text-media.--reverse .s-text-media__col.--content {
    padding-left: 0;
  }
}

@media (max-width: 35.999em) {
  .s-text-media.--reverse .s-text-media__col.--content {
    padding-inline: 1rem;
  }
}

@media (max-width: 61.999em) {
  .s-text-media.--reverse .s-text-media__col.--content {
    order: 1;
  }
}

@media (max-width: 47.999em) {
  .s-text-media {
    --radius: 2.5rem;
  }
}

.s-typography__img {
  align-items: center;
  height: 32.125rem;
  display: flex;
}

@media (max-width: 74.999em) {
  .s-typography__img {
    width: 20rem;
    height: 16.125rem;
  }
}

.c-standalone-header__content {
  max-width: 58rem;
  margin-left: auto;
  margin-right: auto;
}

.c-svg {
  opacity: 0;
  transition: opacity .3s;
}

.c-svg.entered {
  opacity: 1;
}

.c-svg.--colored path {
  fill: var(--color);
}

.c-svg.--primary {
  --color: var(--c-primary);
}

.c-svg.--conversion {
  --color: var(--c-conversion);
}

.c-svg.--black {
  --color: var(--c-text);
}

.c-vr {
  width: .0625rem;
}

.w-author-contact {
  --p: 3rem;
  --radius: 3.5rem;
  --gap: 3rem;
  padding: var(--p);
  border-radius: var(--radius);
  gap: var(--gap);
  max-width: 27rem;
}

@media (max-width: 35.999em) {
  .w-author-contact {
    --p: 2rem;
    --gap: 2rem;
    --radius: 2.5rem;
  }
}

.w-author-contact__hr {
  height: .0625rem;
}

.w-author-contact__abstract {
  top: 0;
  right: 0;
  transform: translateX(25%)translateY(-25%);
}

.w-author-contact__radial {
  top: 0;
  right: 0;
  transform: translateX(30%)translateY(-30%);
}

.w-author-cta__image-col {
  --size: 13rem;
  --p: 1.5rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  padding: var(--p);
}

.w-author-cta__image {
  border-radius: 50%;
}

.w-author-cta__body {
  --gap: 2.5rem;
  gap: var(--gap);
}

@container (width <= 500px) {
  .w-author-cta__body {
    --gap: 1.5rem;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.w-author-cta__button {
  margin-top: 2rem;
}

.w-base {
  --p: 3.5rem;
  --radius: 3.5rem;
  padding: var(--p);
  border-radius: var(--radius);
}

@media (max-width: 119.999em) {
  .w-base {
    --p: 3rem;
    --radius: 3rem;
  }
}

@media (max-width: 35.999em) {
  .w-base {
    --p: 2rem;
    --radius: 2.5rem;
  }
}

.entry-content {
  font-size: 1.125rem;
}

@media (max-width: 119.999em) {
  .entry-content {
    font-size: 1rem;
  }
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin: 3rem 0;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  color: #1d1d1b;
  text-align: center;
  background: #ebebeb63;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transform: none;
}

.entry-content blockquote {
  background-color: var(--c-secondary);
  border-radius: 1.5rem;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

@media (max-width: 35.999em) {
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
    margin-bottom: 1.5rem !important;
  }
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  border-radius: 3.5rem;
  max-width: 100%;
  margin-block: 1rem;
}

@media (max-width: 35.999em) {
  .entry-content img {
    border-radius: 2rem;
  }
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #2751f6;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 600;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: #2751f6;
  border-radius: 0 .875rem .875rem 0;
  width: .5rem;
  height: .875rem;
  display: block;
  position: absolute;
  top: .3125rem;
  left: 0;
}

@media (max-width: 119.999em) {
  .entry-content ul > li:before {
    top: .21875rem;
  }
}

.entry-content ul, .entry-content ol {
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: 1.5rem;
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #ebebeb;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content thead {
  color: #fff;
  background-color: #2751f6;
  font-size: 1rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #1d1d1b;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #ebebeb63;
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  background: #ebebeb;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

/*# sourceMappingURL=index.css.map */

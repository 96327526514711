.img--ball {
  @include media-breakpoint-down(xxxl) {
    --square: 200px;
  }

  @include media-breakpoint-down(lg) {
    --square: 144px;
  }

  @include media-breakpoint-down(md) {
    --square: 88px;
  }
}

.c-heading-pill {
  --height: 80px;
  --radius: calc(var(--height) / 2);
  --rotate: 0deg;

  height: var(--height);
  border-radius: var(--radius);
  aspect-ratio: 3/1;

  @include media-breakpoint-down(xxxl) {
    --height: 72px;
  }

  @include media-breakpoint-down(sm) {
    --height: 56px;
  }

  &:hover {
    --rotate: 30deg;
  }
}

.c-heading-pill__inner {
  transition: transform 1s ease;
  transform: translateX(-50%) translateY(-50%) rotate(var(--rotate));
  will-change: transform;
}

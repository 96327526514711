@use "settings/s-colors" as colors;

.f-textarea {
  --border-color: var(--c-text);
  --font-color: var(--c-text);
  --font-size: 20px;
  --padding: 30px 32px;
  --min-height: 87px;

  color: var(--font-color);
  font-size: var(--font-size);

  &.--invalid {
    --border-color: var(--c-danger);
  }

  @include media-breakpoint-down(xxxl) {
    --font-size: 18px;
    --padding: 22px 24px;
    --min-height: 71px;
  }

  @include media-breakpoint-down(sm) {
    --font-size: 14px;
    --padding: 14px 8px;
    --min-height: 50px;
  }

  &.--active {
    --font-color: var(--c-primary);
    --border-color: var(--c-primary);
  }
}

.f-textarea__wrap {
  border-bottom: 1px solid var(--border-color);
}

.f-textarea__input {
  background-color: transparent;
  color: var(--font-color);
  padding: var(--padding);
  min-height: var(--min-height);
  height: auto;
}

.f-switch-theme__icon {
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: var(--c-button);
  }
}

.s-gallery__content {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.s-gallery__column.--reverse {
  flex-direction: column-reverse;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.s-gallery__item {
  .img {
    border-radius: 56px;

    @include media-breakpoint-down(md) {
      border-radius: 42px;
    }

    @include media-breakpoint-down(sm) {
      border-radius: 28px;
    }
  }
}

.f-newsletter {
  --p: 80px;
  --icon-size: 112px;
  --radius: 56px;
  --heading-fz: 32px;
  --gap: 40px;
  --header-gap: 32px;
  --button-display: block;
  --button-icon-display: none;

  @container (max-width: 896px) {
    --p: 56px;
    --gap: 32px;
    --header-gap: 24px;
    --heading-fz: 24px;
    --button-display: none;
    --button-icon-display: block;

    .f-newsletter__arrow.--xl {
      display: block;

      & ~ .f-newsletter__arrow {
        display: none;
      }
    }
  }

  @container (max-width: 500px) {
    --p: 24px;
    --gap: 32px;
    --icon-size: 64px;
    --header-gap: 16px;
    --heading-fz: 20px;
    --radius: 40px;

    .f-newsletter__arrow.--l {
      display: block;

      & ~ .f-newsletter__arrow {
        display: none;
      }
    }
  }

  @container (max-width: 350px) {
    --gap: 24px;

    .f-newsletter__arrow.--m {
      display: block;

      & ~ .f-newsletter__arrow {
        display: none;
      }
    }
  }

  border-radius: var(--radius);
  gap: var(--gap);
  padding: var(--p);
}

.f-newsletter__icon {
  width: var(--icon-size);
  height: var(--icon-size);
  padding: 8px;
}

.f-newsletter__button-icon {
  display: var(--button-icon-display);
}

.f-newsletter__button {
  display: var(--button-display);
}

.f-newsletter__heading {
  font-size: var(--heading-fz);
  line-height: 1.25;
}

.f-newsletter__header {
  max-width: 100%;

  .base-header__description {
    font-size: 16px;
    line-height: 20px;
  }
}

.f-newsletter__arrow {
  display: none;
}

.i-about-image__img {
  border-radius: var(--radius);
}

.i-about-image {
  --radius: 40px;

  border-radius: var(--radius);
  transition: transform 0.3s ease;
  transform-origin: bottom center;
  will-change: transform;

  @include media-breakpoint-down(xxxl) {
    --radius: 32px;
  }

  @include media-breakpoint-down(md) {
    --radius: 24px;
  }

  &.--default {
    margin-top: auto;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      mix-blend-mode: color;
      background-color: var(--c-secondary);
      inset: 0;
      border-radius: var(--radius);
      z-index: -1;
      transform: translate3d(0, 0, 0);
    }

    .i-about-image__img {
      opacity: 0.8;
      filter: grayscale(1);
      border-radius: var(--radius);
    }
  }

  &.--highlight {
    margin-bottom: 86px;

    @include media-breakpoint-up(xxxl) {
      margin-bottom: 48px;
    }

    @include media-breakpoint-down(xl) {
      margin-bottom: 56px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  &.--left {
    --skew: -9deg;
  }

  &.--right {
    --skew: 9deg;
  }

  &.--skew {
    transform: rotate(var(--skew));
  }
}

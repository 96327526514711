.c-scroll-indicator {
  // Fuck Safari
  bottom: -0.5px;

  @keyframes dot-slide {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(16px);
    }

    to {
      opacity: 1;
      transform: translateX(-50%) translateY(52px);
    }
  }

  --dot-size: 8px;
  --dot-border-radius: calc(var(--dot-size) / 2);

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(16px);
    background-color: var(--c-primary);
    animation: dot-slide 1.5s infinite normal forwards;
    animation-timing-function: ease;
    opacity: 0;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }

  &.--view-hidden {
    &::before {
      animation-play-state: paused;
    }
  }
}

.u-scrollbar {
  .os-scrollbar {
    --os-handle-bg: var(--c-primary);
    --os-handle-bg-hover: var(--c-primary);
    --os-handle-bg-active: var(--c-primary);
    --os-handle-border-radius: 4px;
    --os-size: 8px;
    --os-track-bg: var(--c-secondary);
    --os-track-bg-hover: var(--c-secondary);
    --os-track-bg-active: var(--c-secondary);

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.i-service__icon-stack {
  --offset: 24px;

  bottom: var(--offset);
  right: var(--offset);

  @include media-breakpoint-down(xxxl) {
    --offset: 16px;
  }
}

.i-service__abstract {
  height: var(--abstract-size);
  width: var(--abstract-size);
  transform: translateY(var(--abstract-transform-y));
  transition: transform 0.5s ease;
}

.i-service__heading {
  --font-size: 32px;

  font-size: var(--font-size);

  @include media-breakpoint-down(xxxl) {
    --font-size: 24px;
  }

  @include media-breakpoint-down(xxl) {
    --font-size: 20px;
  }
}

.i-service__link {
  text-decoration: none;
  color: var(--c-button);

  &:hover,
  &:focus {
    color: var(--c-button);
  }

  @media (hover: hover) {
    &:hover {
      --icon-rotate: -15deg;
      --abstract-transform-y: 8px;
    }
  }
}

.i-service {
  --radius: 56px;
  --padding: 24px 24px 40px;
  --icon-rotate: 0deg;
  --icon-size: 72px;
  --abstract-size: 122px;
  --abstract-transform-y: 0;
  --gap: 40px;
  --translate-y: 0;

  border-radius: var(--radius);
  padding: var(--padding);
  transition: transform 0.5s ease;
  will-change: transform;
  background-color: var(--c-surface);
  gap: var(--gap);
  transform: translateY(var(--translate-y));

  @include media-breakpoint-down(xxxl) {
    --radius: 48px;
    --padding: 16px 16px 32px;
    --gap: 32px;
  }

  @include media-breakpoint-down(md) {
    --padding: 16px 16px 24px;
    --gap: 32px;
  }

  @include media-breakpoint-down(sm) {
    --icon-size: 56px;
  }

  &:has(.i-service__link:hover) {
    @include media-breakpoint-up(xxl) {
      --translate-y: 16px;
    }
  }

  &.--order-2 {
    .i-service__abstract {
      left: 25%;
    }
  }

  &.--order-4 {
    .i-service__abstract {
      left: 12%;
    }
  }
}

.i-service__body {
  --padding-inline: 16px;

  padding-inline: var(--padding-inline);

  @include media-breakpoint-down(md) {
    --padding-inline: 8px;
  }
}

.i-service__icon {
  --border-radius: calc(var(--icon-size) / 2);

  height: var(--icon-size);
  width: var(--icon-size);
  border-radius: var(--border-radius);
  rotate: var(--icon-rotate);
  transition: rotate 0.5s ease;

  @include media-breakpoint-down(sm) {
    --size: 56px;
  }

  img {
    width: 48px;
    height: 48px;
  }
}

.i-service__semi-circle {
  --height: var(--icon-size);
}

/* stylelint-disable no-descending-specificity */

body.--dark-theme {
  .i-service {
    background-color: var(--c-text);
  }

  .i-service__icon {
    background-color: var(--c-text);
  }

  .i-service__semi-circle {
    --background: var(--c-button);
  }
}
/* stylelint-enable no-descending-specificity */

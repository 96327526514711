a {
  color: var(--c-primary);
  transition: text-decoration-color 0.3s ease;
  will-change: text-decoration-color;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: var(--c-primary);
    text-decoration-color: transparent;
  }
}

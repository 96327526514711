.container {
  --padding-inline: 24px;

  padding-right: var(--padding-inline);
  padding-left: var(--padding-inline);

  @include media-breakpoint-up(xxxl) {
    --padding-inline: 24px;
  }

  @include media-breakpoint-down(sm) {
    --padding-inline: 16px;
  }
}

@use "settings/s-colors" as colors;

.f-base {
  display: block;
  width: 100%;
  background-clip: padding-box;
  padding: 30px 32px;
  appearance: none;
  border: none;
  min-height: 87px;

  &:focus {
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: colors.$gray;
  }
}

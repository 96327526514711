.s-contact-info__body {
  gap: 72px;

  @include media-breakpoint-only(xxl) {
    gap: 48px;
  }

  @include media-breakpoint-down(lg) {
    gap: 48px;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}

.s-contact-info__content-col {
  width: 100%;

  @include media-breakpoint-down(md) {
    row-gap: 32px;
    text-align: left;
  }
}

.s-contact-info {
  --padding: 80px;
  --radius: 56px;

  padding: var(--padding);
  border: 1px solid var(--c-secondary);
  border-radius: var(--radius);

  @include media-breakpoint-only(xxl) {
    --padding: 80px;
  }

  @include media-breakpoint-down(lg) {
    --padding: 32px;
    --radius: 40px;
  }
}

.s-contact-info__contact-title {
  font-size: 24px;
  line-height: 30px;

  @include media-breakpoint-down(xxl) {
    font-size: 20px;
    line-height: 25px;
  }
}

.s-contact-info__contacts {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  justify-content: space-between;
  gap: 40px;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    row-gap: 64px;
  }

  @include media-breakpoint-up(xxl) {
    row-gap: 104px;
    column-gap: 56px;
  }
}

@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-down(xl) {
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--c-secondary);
    box-shadow: shadows.$shadow;
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    will-change: transform;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.--open {
      transform: translateX(0);
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }
}

.m-nav__list {
  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: start;
  }

  @include media-breakpoint-down(xl) {
    margin-top: 32px;
  }
}

.m-nav__semi-circle {
  --height: 56px;
}

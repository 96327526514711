.c-404-pill {
  --height: 224px;
  --radius: 400px;
  --rotate: 0deg;

  height: var(--height);
  border-radius: var(--radius);
  aspect-ratio: 5/2;

  @include media-breakpoint-down(xxxl) {
    --height: 150px;
  }

  @include media-breakpoint-down(xxl) {
    --height: 120px;
  }

  @include media-breakpoint-down(lg) {
    --height: 88px;
  }

  @include media-breakpoint-down(md) {
    --height: 120px;
  }

  &:hover {
    --rotate: 30deg;
  }
}

.c-404-pill__inner {
  transition: transform 1s ease;
  will-change: transform;
  transform: translateX(-50%) translateY(-50%) rotate(var(--rotate));
}

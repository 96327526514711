.s-double-content__grid {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-double-content {
  --radius: 56px;

  .s-double-content__title {
    margin-top: 0;
  }

  .img {
    margin-block: 0;
    border-radius: var(--radius);
  }

  @include media-breakpoint-down(sm) {
    --radius: 40px;
  }
}

.s-double-content__body {
  padding-inline: 24px;

  @include media-breakpoint-down(sm) {
    padding-inline: 16px;
  }
}

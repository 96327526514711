.g-posts {
  grid-template-columns: repeat(3, 1fr);

  @include media-breakpoint-down(xxl) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(lg) {
    max-width: 608px;
    margin-inline: auto;
  }
}

.u-h1 {
  --size: 136px;
  --line-height: 136px;

  @include media-breakpoint-down(xxxl) {
    --size: 112px;
    --line-height: 120px;
  }

  @include media-breakpoint-down(xl) {
    --size: 96px;
    --line-height: 96px;
  }

  @include media-breakpoint-down(lg) {
    --size: 72px;
    --line-height: 80px;
  }

  @include media-breakpoint-down(sm) {
    --size: 40px;
    --line-height: 48px;
  }
}

.u-h2 {
  --size: 64px;
  --line-height: 80px;

  @include media-breakpoint-down(xxxl) {
    --size: 56px;
    --line-height: 64px;
  }

  @include media-breakpoint-down(lg) {
    --size: 24px;
    --line-height: 32px;
  }
}

.u-h3 {
  --size: 32px;
  --line-height: 40px;

  @include media-breakpoint-down(xxxl) {
    --size: 24px;
    --line-height: 30px;
  }

  @include media-breakpoint-down(lg) {
    --size: 20px;
    --line-height: 24px;
  }
}

.u-h4 {
  --size: 20px;
  --line-height: 24px;

  @include media-breakpoint-down(lg) {
    --size: 18px;
  }
}

.u-h5 {
  --size: 18px;
  --line-height: 22px;

  @include media-breakpoint-down(lg) {
    --size: 16px;
    --line-height: 18px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
}

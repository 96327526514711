.s-posts-list__filters-row {
  --mb: 64px;

  margin-bottom: var(--mb);

  @include media-breakpoint-down(sm) {
    --mb: 40px;
  }
}

.s-posts-list__search-col {
  @include media-breakpoint-down(xl) {
    order: -1;
  }
}

.s-posts-list__tags {
  @include media-breakpoint-down(sm) {
    gap: 8px;
  }
}

.d-hero-sphere {
  --square: 544px;
  --bottom: 208px;
  --right: 150px;

  bottom: var(--bottom);
  right: var(--right);

  @include media-breakpoint-down(xxxl) {
    --right: -24px;
    --bottom: 100px;
  }

  @include media-breakpoint-down(xxl) {
    --bottom: 32px;
    --square: 400px;
  }

  @include media-breakpoint-down(lg) {
    --square: 300px;
  }

  @include media-breakpoint-down(md) {
    --square: 200px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

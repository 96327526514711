@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    color: var(--c-text);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--c-text);
      text-decoration-color: var(--c-text);
    }
  }

  &.--primary {
    color: var(--c-primary);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--c-primary);
      text-decoration-color: var(--c-primary);
    }
  }

  &.--conversion {
    color: var(--c-conversion);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--c-conversion);
      text-decoration-color: var(--c-conversion);
    }
  }

  &.--conversion-600 {
    color: var(--c-conversion-600);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--c-conversion-600);
      text-decoration-color: var(--c-conversion-600);
    }
  }
}

@use "settings/s-colors" as colors;

.btn {
  &.--primary {
    --background: var(--c-conversion);
    --box-shadow: var(--shadow-lg);
    --box-shadow-hover: var(--shadow-xl);
    --before-opacity: 0;

    background-color: var(--background);
    box-shadow: var(--box-shadow);
    transition: box-shadow 0.3s ease;
    will-change: box-shadow;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      display: block;
      inset: 0;
      z-index: -1;
      background-image: linear-gradient(to right, var(--c-conversion), var(--c-conversion-500));
      border-radius: var(--border-radius);
      opacity: var(--before-opacity);
      transition: opacity 0.3s ease;
      will-change: opacity;
    }

    @media (hover: hover) {
      &:hover {
        --box-shadow: var(--box-shadow-hover);
        --before-opacity: 1;
      }
    }
  }

  &.--outline {
    --font-color: var(--c-text);
    --border-color: var(--c-secondary);
    --background: var(--c-surface);

    border: 1px solid var(--border-color);
    transition: background-color 0.3s ease;
    will-change: background-color;
    background-color: var(--background);

    @media (hover: hover) {
      &:hover {
        --background: var(--c-secondary-100);
      }
    }
  }

  &.--ghost {
    --background: transparent;
    --font-color: var(--c-text);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    @media (hover: hover) {
      &:hover {
        --background: var(--c-secondary-100);
      }
    }
  }
}

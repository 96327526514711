.g-about-images {
  grid-template-columns: repeat(3, 1fr);

  @include media-breakpoint-up(xxxl) {
    grid-template-columns: 568px 624px 568px;
  }

  @include media-breakpoint-down(xxxl) {
    gap: 16px;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(3, 208px);
  }
}

.s-service-intro__description {
  --mw: 896px;

  max-width: var(--mw);

  @include media-breakpoint-up(sm) {
    min-height: 56px;
  }

  @include media-breakpoint-down(xxxl) {
    --mw: 787px;
  }

  @include media-breakpoint-down(xxl) {
    --mw: 70%;
  }

  @include media-breakpoint-down(xl) {
    --mw: 60%;
  }

  @include media-breakpoint-down(md) {
    --mw: 100%;

    font-size: 16px;
  }
}

.s-service-intro__title-semi-circle {
  --height: 80px;

  @include media-breakpoint-down(xxxl) {
    --height: 72px;
  }

  @include media-breakpoint-down(sm) {
    --height: 56px;
  }
}

.s-service-intro__title {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

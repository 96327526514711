@use "settings/s-colors" as colors;

.g-services {
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 2fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-down(xxl) {
    max-width: 912px;
    margin-inline: auto;
  }

  @include media-breakpoint-down(md) {
    max-width: 456px;
  }
}

.g-services__radial {
  bottom: 32px;
  right: 32px;
  transform: translateX(50%) translateY(45%);
  z-index: -1;

  &.--lg {
    --size: 536px;
    --opacity: 0.5;

    bottom: 88px;

    @include media-breakpoint-down(xxxl) {
      --size: 488px;
    }

    @include media-breakpoint-down(md) {
      --size: 304px;
    }
  }

  &.--sm {
    --size: 312px;

    @include media-breakpoint-down(xxxl) {
      --size: 288px;
    }

    @include media-breakpoint-down(md) {
      --size: 176px;
    }
  }
}

@use "settings/s-colors" as colors;

.main-footer-column__list {
  &.--cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 96px;

    @include media-breakpoint-down(xxxl) {
      column-gap: 80px;
    }

    @include media-breakpoint-down(xxl) {
      column-gap: 56px;
    }

    @include media-breakpoint-down(sm) {
      display: flex;
    }
  }
}

.w-author-cta__image-col {
  --size: 208px;
  --p: 24px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
  padding: var(--p);
}

.w-author-cta__image {
  border-radius: 50%;
}

.w-author-cta__body {
  --gap: 40px;

  gap: var(--gap);

  @container (max-width: 500px) {
    --gap: 24px;

    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}

.w-author-cta__button {
  margin-top: 32px;
}

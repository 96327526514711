@use "settings/s-colors.scss" as colors;

.u-color-primary {
  color: var(--c-primary);
}

.u-color-text {
  color: var(--c-text);
}

.u-color-text-100 {
  color: var(--c-text-100);
}

.u-color-conversion {
  color: var(--c-conversion);
}

.u-color-conversion-600 {
  color: var(--c-conversion-600);
}

.u-color-white {
  color: var(--c-white);
}

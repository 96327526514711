@use "settings/s-colors.scss" as colors;

.link {
  --direction-offset: 4px;

  &.--animation-right {
    @media (hover: hover) {
      &:hover {
        svg {
          transform: translateX(var(--direction-offset));
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
      }
    }
  }

  &.--animation-left {
    @media (hover: hover) {
      &:hover {
        svg {
          transform: translateX(calc(-1 * var(--direction-offset)));
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
        }
      }
    }
  }

  &.--animation-zoom {
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
}

.c-desc-decoration {
  img {
    width: 896px;
    height: 80px;
    max-width: auto;
    object-fit: cover;

    --opacity: 0;

    opacity: var(--opacity);

    &.--show {
      --opacity: 1;
    }
  }
}

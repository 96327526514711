.i-testimonial {
  img {
    object-fit: contain;
    max-height: 120px;

    @include media-breakpoint-down(xxxl) {
      max-height: 96px;
    }

    @include media-breakpoint-down(xl) {
      max-height: 96px;
    }

    @include media-breakpoint-down(sm) {
      max-height: 64px;
    }
  }
}

@use "settings/s-colors" as colors;

.i-technology {
  --height: 160px;
  --radius: 56px;
  --p: 48px;

  height: var(--height);
  border-radius: var(--radius);
  padding: var(--p);

  &.--empty {
    --size: 96px;
    --radius: 96px;

    width: var(--size);
    height: var(--size);

    &.--index-1 {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    @include media-breakpoint-down(xxxl) {
      --size: 80px;
    }

    @include media-breakpoint-down(sm) {
      --size: 48px;
    }
  }

  img {
    max-height: 64px;
    max-width: 87px;
  }

  @include media-breakpoint-down(xxxl) {
    --p: 32px;
  }

  @include media-breakpoint-down(sm) {
    --height: 94px;
    --radius: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

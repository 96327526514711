$primary-100: #e7f0ff;
$primary-300: #7387ac;
$primary-400: #476cfe;
$primary: #2751f6;

$gray: #a9a9b2;
$gray-200: #ebebeb;

$white: #fff;

$black: #081828;
$black-400: #1d1d1b;

$danger: #e21c3d;
$danger-200: #f9dede;

$success: #48a463;
$success-200: #e7f4e4;

$warning: #fdb81e;
$warning-200: #fff1d2;

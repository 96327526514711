@use "settings/s-colors.scss" as colors;

.i-tag {
  --padding-x: 16px;
  --padding-y: 2px;
  --font-size: 16px;
  --min-height: 32px;

  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  font-size: var(--font-size);

  &.--sm {
    --padding-x: 8px;
    --padding-y: 2px;
    --min-height: 24px;
    --font-size: 12px;
  }

  &.--md {
    --padding-x: 16px;
    --padding-y: 2px;
    --min-height: 40px;
    --font-size: 12px;
  }

  &.--lg {
    --padding-x: 24px;
    --padding-y: 4px;
    --min-height: 48px;

    @include media-breakpoint-down(sm) {
      --padding-x: 16px;
      --padding-y: 2px;
      --min-height: 40px;
      --font-size: 12px;
    }
  }
}

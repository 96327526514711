.s-categories-list__grid {
  --gap: 80px;

  gap: var(--gap);
}

.s-categories-list__line {
  height: 1px;
  flex-grow: 1;
  background-color: var(--c-secondary);

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.s-categories-list__header {
  row-gap: 32px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    text-align: center;
  }
}

.s-categories-list__button {
  @include media-breakpoint-down(md) {
    margin-left: 0;
    width: 100%;
  }
}

.s-colors-typo__grid {
  --mw: 896px;

  display: grid;
  row-gap: 56px;
  max-width: var(--mw);

  @include media-breakpoint-up(lg) {
    --mw: 100%;

    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content {
  > a {
    display: block;
  }

  img {
    max-width: 100%;
    margin-block: 16px;
    border-radius: 56px;

    @include media-breakpoint-down(sm) {
      border-radius: 32px;
    }
  }
}

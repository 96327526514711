.f-contact__grid {
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    column-gap: 64px;
  }

  @include media-breakpoint-up(xxxl) {
    column-gap: 80px;
  }
}

.f-contact__footer {
  @include media-breakpoint-down(lg) {
    margin-top: 24px;
  }
}

.f-contact__terms {
  a {
    font-weight: 500;
    color: var(--c-text);
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.f-contact__body {
  @include media-breakpoint-up(lg) {
    gap: 80px;
  }

  @include media-breakpoint-up(xxxl) {
    column-gap: 96px;
  }
}

.f-contact__header {
  @include media-breakpoint-up(lg) {
    margin-bottom: 8px;
  }

  @include media-breakpoint-up(xxxl) {
    margin-bottom: 24px;
  }
}

.f-contact__tags {
  max-width: 910px;
}

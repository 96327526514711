.s-service-intro__mask {
  --ratio: 38/15;

  img {
    aspect-ratio: var(--ratio);
  }

  @include media-breakpoint-down(xxxl) {
    --ratio: 170/81;
  }

  @include media-breakpoint-down(sm) {
    --ratio: 38/23;
  }
}

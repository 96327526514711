.s-cta__decoration {
  height: 144px;
  border-radius: 72px;

  .img {
    border-radius: 72px;
    overflow: hidden;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 16px;
  }
}

.s-cta__body {
  border-radius: 56px;

  @include media-breakpoint-down(md) {
    padding: 32px;
    border-radius: 32px;
    text-align: center;
  }
}

.s-cta__radial {
  --size: 232px;

  top: 0;
  left: 0;
  transform: translateX(-30%) translateY(-30%);
}

.s-cta__abstract {
  left: 10%;
  bottom: 24px;
  transform: translateY(50%);

  @include media-breakpoint-down(md) {
    width: 88px;
  }
}

.s-cta__button {
  @include media-breakpoint-down(md) {
    flex-grow: 1;
  }
}

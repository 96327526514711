@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  font-size: 18px;

  @include media-breakpoint-down(xxxl) {
    font-size: 16px;
  }

  // Lobotomized Owls
  & > * + * {
    margin-block-start: 16px;
  }

  // Empty tags white space fix

  strong:empty,
  b:empty,
  p:empty {
    display: none;
  }

  hr {
    border: none;
    background: colors.$gray-200;
    height: 1px;
    margin: general.$spacing * 6 0;
  }

  .wp-caption {
    border: 0;
    padding: 0;
    text-align: center;
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 16px;
    color: colors.$black-400;
    margin-left: 0;
    transform: none;
    text-align: center;
    line-height: general.$spacing * 3;
    padding: 4px 10px;
    background: rgb(235 235 235 / 39%);
    margin-bottom: 0;
  }
}

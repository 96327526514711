.i-menu {
  --padding-left: 16px;
  --padding-right: 48px;
  --background: transparent;
  --padding-block: 23.5px;
  --fz: 20px;
}

.i-menu__link {
  padding-block: var(--padding-block);
  min-height: 40px;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  text-decoration: none;
  transition: background-color 0.3s ease;
  will-change: background-color;
  color: var(--c-text);
  font-size: var(--fz);

  &:hover {
    color: var(--c-text);
  }
}

// Desktop

@include media-breakpoint-up(xl) {
  .i-menu {
    --padding-left: 16px;
    --padding-right: 16px;
    --fz: 16px;
  }
}

@include media-breakpoint-only(xl) {
  .i-menu {
    --fz: 14px;
  }
}

.base-section {
  --padding-y: 96px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(xxxl) {
    --padding-y: 72px;
  }

  @include media-breakpoint-down(md) {
    --padding-y: 40px;
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--none {
    --padding-y: 0;
  }
}

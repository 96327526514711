.i-read-time {
  --fz: 18px;

  font-size: var(--fz);

  @include media-breakpoint-down(xxxl) {
    --fz: 16px;
  }

  @include media-breakpoint-down(sm) {
    --fz: 14px;

    gap: 4px;
  }
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-1\/2 {
  top: 50%;
}

.u-left-1\/2 {
  left: 50%;
}

.u-left-0 {
  left: 0;
}

.u-translate-x-1\/2 {
  transform: translateX(-50%);
}

.u-translate-y-1\/2 {
  transform: translateY(-50%);
}

.u-translate-1\/2 {
  transform: translateX(-50%) translateY(-50%);
}

.s-hero__radial {
  &.--lg {
    --size: 536px;

    top: 0;
    right: 0;
    transform: translateX(calc(50% - 48px)) translateY(calc(-50% + 100px));

    @include media-breakpoint-down(xxxl) {
      --size: 464px;
    }

    @include media-breakpoint-down(sm) {
      --size: 240px;

      left: 48px;
      top: 48px;
      right: auto;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.--sm {
    --size: 368px;

    left: 48px;
    bottom: 48px;
    transform: translateX(-50%) translateY(50%);

    @include media-breakpoint-down(xxxl) {
      --size: 328px;
    }

    @include media-breakpoint-down(sm) {
      --size: 240px;

      left: auto;
      right: 48px;
      transform: translateX(50%) translateY(50%);
    }
  }
}

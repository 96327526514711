.s-intro__description-grid {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 32px;
  }

  @include media-breakpoint-up(xxxl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-intro__description {
  max-width: 100%;
  font-size: 20px;

  span {
    color: var(--c-primary);
    font-weight: 500;
  }

  @include media-breakpoint-down(xxxl) {
    font-size: 18px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.s-intro__title {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    display: flex;
  }
}

.s-hero-reference__bg-decoration {
  border-radius: 56px;
  display: block;
  width: calc(100vw - (2 * 8px));
  height: calc(100% - (2 * 40px) - 112px);
  position: absolute;
  top: 40px;
  left: 8px;
  z-index: -1;

  img {
    width: auto;
  }

  @include media-breakpoint-up(md) {
    width: calc(100vw - (2 * 24px));
    height: calc(100% - (2 * 72px) - 112px);
    left: 24px;
    top: 72px;
  }

  @include media-breakpoint-up(xxxl) {
    height: calc(100% - (2 * 96px) - 112px);
    top: 96px;
  }
}

.s-hero-reference {
  &.--bottom-0 {
    .s-hero-reference__bg-decoration {
      height: calc(100% - 40px - 112px);

      @include media-breakpoint-up(md) {
        height: calc(100% - 72px - 112px);
        top: 72px;
      }

      @include media-breakpoint-up(xxxl) {
        height: calc(100% - 96px - 112px);
        top: 96px;
      }
    }
  }

  &.--top-0 {
    .s-hero-reference__bg-decoration {
      height: calc(100% - 40px - 112px);
      top: 0;

      @include media-breakpoint-up(md) {
        height: calc(100% - 72px - 112px);
      }

      @include media-breakpoint-up(xxxl) {
        height: calc(100% - 96px - 112px);
      }
    }
  }
}

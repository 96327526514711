.i-faq__body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  transform: translateZ(0);
}

.i-faq__title {
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 20px;
  }
}

.i-faq__body-inner {
  line-height: 20px;

  @include media-breakpoint-up(sm) {
    padding-left: 80px;
    line-height: 25px;
    font-size: 20px;
  }
}

.i-faq {
  --p: 40px;
  --toggler-size: 24px;
  --minus-display: none;
  --plus-display: block;

  padding: var(--p);
  border-bottom: 1px solid var(--c-secondary);
  border-radius: var(--border-radius);

  @include media-breakpoint-down(sm) {
    --p: 24px;
    --toggler-size: 16px;
  }

  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }

  &.--open {
    --minus-display: block;
    --plus-display: none;

    .i-faq__body {
      grid-template-rows: 1fr;
    }
  }

  &.--hidden {
    display: none;
  }
}

.i-faq__icon-border,
.i-faq__toggler {
  --size: 88px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  @include media-breakpoint-down(sm) {
    --size: 48px;
  }
}

.i-faq__icon {
  --size: 56px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  @include media-breakpoint-down(sm) {
    --size: 32px;
  }
}

.i-faq__toggler-icon {
  width: var(--toggler-size);
  height: var(--toggler-size);

  &.--minus {
    display: var(--minus-display);
  }

  &.--plus {
    display: var(--plus-display);
  }
}

.i-faq__header {
  @include media-breakpoint-down(sm) {
    gap: 8px;
    align-items: flex-start;
  }
}

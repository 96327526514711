.m-header {
  --shadow-color: transparent;
  --asymmetry: 32px;
  --min-height: 120px;
  --p-block: 26px;
  --logo-max-w: 152px;
  --semi-circle-height: 52px;
  --button-height: 52px;
  --bg-opacity: 1;

  top: 0;
  left: 0;
  position: fixed;
  z-index: 5;
  width: 100%;
  transition: transform 0.5s ease;

  &::before {
    content: "";
    position: absolute;
    display: block;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-surface);
    opacity: var(--bg-opacity);
  }

  &.headroom--unpinned {
    @include media-breakpoint-up(xl) {
      transform: translateY(-100%);
    }
  }

  &.headroom--not-top {
    --p-block: 16px;
    --logo-max-w: 120px;
    --semi-circle-height: 48px;
    --button-height: 48px;
    --bg-opacity: 0.9;

    &::before {
      backdrop-filter: blur(10px);
    }

    @include media-breakpoint-only(xl) {
      --logo-max-w: 80px;
    }

    @include media-breakpoint-down(sm) {
      --logo-max-w: 80px;
    }
  }

  @include media-breakpoint-only(xl) {
    --logo-max-w: 100px;
  }

  @include media-breakpoint-down(sm) {
    --p-block: 14px;
    --logo-max-w: 110px;
  }
}

.m-header__body {
  padding-block: var(--p-block);
  transition: padding 0.3s ease;
  will-change: padding;

  @include media-breakpoint-down(sm) {
    --asymmetry: 16px;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: calc(100% + var(--asymmetry));
    background-color: var(--c-secondary);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.m-header__semi-circle {
  --height: var(--semi-circle-height);

  transition: height 0.3s ease;
  transition-property: height, width;
  will-change: height, width;
}

.m-header-offset {
  --offset: 120px;

  padding-top: var(--offset);

  @include media-breakpoint-down(sm) {
    --offset: 80px;
  }
}

.m-header-logo {
  max-width: var(--logo-max-w);
  transition: max-width 0.3s ease;
  will-change: max-width;
}

.m-header .m-header__btn-form.btn.--xs {
  --min-height: var(--button-height);

  transition: min-height 0.3s ease;
  will-change: min-height;
}

.s-double__grid {
  .f-newsletter {
    padding: 56px;

    .f-newsletter__button {
      display: none;
    }

    .f-newsletter__button-icon-only {
      display: block;
    }
  }

  --mw: 896px;

  display: grid;
  row-gap: 56px;
  max-width: var(--mw);

  @include media-breakpoint-up(xxl) {
    --mw: 100%;

    grid-template-columns: repeat(2, 1fr);
  }
}

.i-author__description {
  p {
    margin-bottom: 0;
  }
}

.i-author__title {
  font-size: 18px;
}

.i-author {
  --image-size: 64px;

  @include media-breakpoint-down(sm) {
    --image-size: 48px;

    .i-author__body {
      row-gap: 0;
    }
  }

  &.--compact {
    --image-size: 56px;

    @include media-breakpoint-down(xxxl) {
      --image-size: 48px;
    }

    .i-author__title {
      font-size: 16px;
    }

    .i-author__body {
      row-gap: 0;
    }

    .i-author__rank {
      font-size: 14px;
    }
  }
}

.i-author__image {
  --radius: 50%;

  border-radius: var(--radius);
  width: var(--image-size);
  height: var(--image-size);
  max-width: var(--image-size);
}

.m-contact-success__icon {
  margin-top: -8px;
  border-radius: 50%;
  width: 88px;
  height: 88px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.m-contact-success__header {
  --spacing: 48px;

  width: min(100%, 768px);
  gap: 8px;

  h2 {
    --size: 24px;
  }
}

.s-service-intro__icon-stack {
  --size: 184px;
  --offset: 48px;

  right: var(--offset);
  bottom: var(--offset);
  z-index: 2;

  @include media-breakpoint-down(xxxl) {
    --offset: 40px;
  }

  @include media-breakpoint-down(xxl) {
    --size: 120px;
    --offset: 32px;
  }

  @include media-breakpoint-down(md) {
    --size: 80px;
    --offset: 16px;
  }

  @include media-breakpoint-down(sm) {
    --size: 56px;

    gap: 8px;
  }
}

.s-service-intro__icon {
  border-radius: 50%;
  width: var(--size);
  height: var(--size);

  @include media-breakpoint-down(xxl) {
    --padding: 24px;

    padding: var(--padding);
  }

  @include media-breakpoint-down(md) {
    --padding: 16px;
  }

  @include media-breakpoint-down(sm) {
    --padding: 8px;
  }
}

.s-service-intro__semi-circle {
  --height: var(--size);
}

.s-service-intro__abstract {
  --size: 200px;

  left: var(--abstract-left); // service-intro-section.scss
  top: 50%;
  transform: translateY(-20%);
  width: var(--size);
  height: var(--size);

  @include media-breakpoint-down(xxl) {
    --size: 152px;
  }

  @include media-breakpoint-between(sm, md) {
    --size: 88px;
  }

  @include media-breakpoint-down(sm) {
    --size: 122px;

    transform: translateY(0);
    top: 0;
  }
}

.s-service-intro__radial {
  &.--lg {
    --size: 536px;

    top: 0;
    right: 0;
    transform: translateX(calc(50% - 48px)) translateY(calc(-50% + 100px));

    @include media-breakpoint-down(xxxl) {
      --size: 464px;
    }

    @include media-breakpoint-down(sm) {
      --size: 240px;

      transform: translateX(calc(50% - 48px)) translateY(calc(-50% + 40px));
    }
  }

  &.--sm {
    --size: 368px;

    left: 48px;
    bottom: 48px;
    transform: translateX(-50%) translateY(50%);

    @include media-breakpoint-up(md) {
      left: 25%;
    }

    @include media-breakpoint-up(xxl) {
      left: 35%;
    }

    @include media-breakpoint-down(xxxl) {
      --size: 240px;
    }

    @include media-breakpoint-down(sm) {
      --size: 184px;
    }
  }
}

.s-hero__sun-dial-decoration {
  left: -24px;
  bottom: 230px;

  @include media-breakpoint-down(xxxl) {
    bottom: 130px;
  }

  @include media-breakpoint-down(xxl) {
    bottom: 80px;
  }

  @include media-breakpoint-down(xl) {
    bottom: 40px;
  }

  @include media-breakpoint-down(lg) {
    bottom: 50px;
    width: 122px;
  }

  @include media-breakpoint-down(md) {
    bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    left: calc(50% + 80px);
    top: calc(50% - 72px);
    bottom: auto;
    transform: translateX(-50%) translateY(-50%);
  }
}

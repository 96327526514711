.c-svg {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.entered {
    opacity: 1;
  }

  &.--colored {
    path {
      fill: var(--color);
    }
  }

  &.--primary {
    --color: var(--c-primary);
  }

  &.--conversion {
    --color: var(--c-conversion);
  }

  &.--black {
    --color: var(--c-text);
  }
}

@use "settings/s-colors" as colors;

.flatpickr-calendar {
  --theme-color: var(--c-conversion);
  --theme-color-hover: var(--c-conversion-500);

  .flatpickr-day.selected {
    background: var(--theme-color);
    border-color: var(--theme-color);

    &:hover {
      background: var(--theme-color);
      border-color: var(--theme-color);
    }
  }

  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: var(--theme-color);
  }
}

.g-references {
  grid-template-columns: repeat(12, 1fr);

  @include media-breakpoint-down(xxxl) {
    gap: 16px;
  }

  .g-references__cell {
    --span: 3;

    grid-column: auto / span var(--span);

    @include media-breakpoint-down(xl) {
      --span: 6;
    }

    &:nth-child(6n),
    &:nth-child(7n),
    &:first-child {
      --span: 6;

      @include media-breakpoint-down(xl) {
        --span: 12;
      }
    }
  }
}

.s-post-header__metas {
  @include media-breakpoint-down(lg) {
    gap: 16px;

    .c-vr:first-child {
      display: none;
    }
  }
}

.s-post-header__header {
  max-width: 100%;
}

.s-post-header__tags-categories {
  @include media-breakpoint-down(xxxl) {
    margin-top: 42px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 24px;
  }
}

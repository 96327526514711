.s-intro__tags-categories {
  .g-tags {
    align-items: center;
  }

  .i-tag {
    max-height: 48px;
  }

  @include media-breakpoint-down(xxxl) {
    margin-top: 42px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 24px;
  }
}

.s-intro__metas {
  @include media-breakpoint-down(lg) {
    gap: 16px;

    .c-vr:first-child {
      display: none;
    }
  }
}

.s-intro__button {
  margin-left: auto;

  &[style] {
    .btn {
      color: inherit;

      path {
        fill: currentcolor;
      }
    }
  }
}
